<template>
	<div class="wizard-container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div>
					<img src="@/assets/img/logo.png" />
				</div>
			</div>
		</div>
		<div>
			<!-- You can switch data-color="primary" with one of the next bright colors: "green", "orange", "red", "blue" -->
			<md-card class="md-card-wizard active" data-color="orange">
				<md-card-header>
					<slot name="header">
						<h3 class="card-title">{{ title }}</h3>
						<h3 class="description">{{ subTitle }}</h3>
					</slot>
				</md-card-header>
				<div class="wizard-navigation">
					<ul class="nav nav-pills" role="tablist">
						<li v-for="(tab, index) in tabs" :key="tab.title" role="tab" :id="`step-${tab.tabId}`" class="nav-item wizard-tab-link" :style="linkWidth">
							<a class="nav-link" @click="navigateToTab(index)" :class="[{ active: tab.active }]" data-toggle="tab">
								<tab-item-content :tab="tab"></tab-item-content>
							</a>
						</li>
					</ul>
					<div
						class="moving-tab"
						:class="{ 'error-link': activeTab.hasError }"
						v-if="activeTab"
						style="transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1); width: 100%"
						:style="movingTabStyles"
					>
						<tab-item-content :tab="activeTab" :moving-tab="true"></tab-item-content>
					</div>
				</div>

				<md-card-content>
					<div class="tab-content">
						<slot :activeIndex="activeTabIndex" :activeTab="activeTab"></slot>
					</div>
				</md-card-content>

				<!-- Removed the md-card-actions block (buttons) entirely -->
			</md-card>
		</div>

		<modal v-if="submissionSuccessful">
			<template slot="header">
				<div style="font-size: 2rem">Success! 🎊</div>
			</template>
			<template slot="body">
				<p>Thank you for your application. The team will be in touch once your application has been evaluated.</p>
			</template>
			<template slot="footer">
				<md-button class="md-button md-success" @click="closeModal">Okay</md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/Modal';
import { throttle } from './throttle';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';

export default {
	name: 'simple-wizard',
	props: {
		startIndex: {
			type: Number,
			default: 0,
		},
		title: {
			type: String,
			default: 'Title',
		},
		subTitle: {
			type: String,
			default: 'Subtitle',
		},
		vertical: {
			type: Boolean,
		},
		bursary: { type: Object },
	},
	components: {
		TabItemContent: {
			props: ['tab', 'movingTab'],
			render(h) {
				return h('span', [this.tab.$slots.label || this.tab.label]);
			},
		},
		Modal,
	},
	provide() {
		return {
			addTab: this.addTab,
			removeTab: this.removeTab,
		};
	},
	data() {
		return {
			tabs: [],
			activeTabIndex: 0,
			tabLinkWidth: 0,
			tabLinkHeight: 50,
			loading: false,
			bursaryID: this.$route.params.id,
			studentAlias: '',
			submissionSuccessful: false,
		};
	},
	computed: {
		// Not strictly needed if we’re not using a button text label, but leaving for any future usage
		...mapGetters({
			btnText: 'getBtnText',
		}),
		tabCount() {
			return this.tabs.length;
		},
		linkWidth() {
			let width = 100;
			if (this.tabCount > 0) {
				width = 100 / this.tabCount;
			}
			if (this.vertical) {
				width = 100;
			}
			return { width: `${width}%` };
		},
		activeTab() {
			return this.tabs[this.activeTabIndex];
		},
		movingTabStyles() {
			let translateXValue =
				this.activeTabIndex === 0
					? this.tabLinkWidth * this.activeTabIndex - 8
					: this.activeTabIndex === this.tabCount - 1
					? this.tabLinkWidth * this.activeTabIndex + 8
					: this.tabLinkWidth * this.activeTabIndex;

			let translateYValue = 0;
			if (this.vertical) {
				translateYValue = this.tabLinkHeight * this.activeTabIndex;
				translateXValue = 0;
			}
			let styles = {
				transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`,
			};
			if (this.tabLinkWidth !== 0) {
				styles.width = `${this.tabLinkWidth}px`;
			}
			return styles;
		},
	},
	methods: {
		...mapActions(['updateBtnText', 'resetState']),

		closeModal() {
			this.submissionSuccessful = false;
			this.$router.push({ name: 'apply-bursary' });
		},
		addTab(tab) {
			const index = this.$slots.default.indexOf(tab.$vnode);
			let tabTitle = tab.title || '';
			tab.tabId = `${tabTitle.replace(/ /g, '')}${index}`;
			if (!this.activeTab && index === 0) {
				tab.active = true;
			}
			this.tabs.splice(index, 0, tab);
		},
		removeTab(tab) {
			const index = this.tabs.indexOf(tab);
			if (index > -1) {
				this.tabs.splice(index, 1);
			}
		},
		/**
		 * If you still want to do any validation on tab switch, you can keep this.
		 * Otherwise, you can remove or simplify it.
		 */
		validate(tab) {
			let tabToValidate = tab || this.activeTab;
			let beforeChange = tabToValidate.beforeChange;
			if (beforeChange) {
				return Promise.resolve(beforeChange())
					.then(res => {
						this.activeTab.hasError = res ? false : true;
						return res;
					})
					.catch(() => {
						this.activeTab.hasError = true;
					});
			} else {
				return Promise.resolve(true);
			}
		},
		/**
		 * Called when clicking on a tab.
		 * By default, we just allow immediate navigation.
		 * If you'd like to require validation before switching forward, you can adapt this.
		 */
		async navigateToTab(index) {
			// If you want to allow free navigation without validation, just set activeTabIndex:
			this.activeTabIndex = index;

			// If you want to do validation before letting the user move to a *later* tab:
			// if (index > this.activeTabIndex) {
			//   const isValid = await this.validate();
			//   if (isValid) this.activeTabIndex = index;
			// } else {
			//   this.activeTabIndex = index;
			// }
		},
		onResize() {
			let tabLinks = document.getElementsByClassName('wizard-tab-link');
			if (tabLinks.length > 0 && this.tabCount > 0) {
				let { clientWidth, clientHeight } = tabLinks[0];
				this.tabLinkWidth = clientWidth;
				this.tabLinkHeight = clientHeight;
			}
		},
	},
	mounted() {
		this.activeTabIndex = this.startIndex;
		this.$nextTick(() => {
			if (this.tabs[this.activeTabIndex]) {
				this.tabs[this.activeTabIndex].active = true;
			}
			this.onResize();
		});
		window.addEventListener(
			'resize',
			() => {
				throttle(this.onResize, 40);
			},
			false
		);
	},
	watch: {
		activeTabIndex(newValue, oldValue) {
			if (newValue !== oldValue) {
				let oldTab = this.tabs[oldValue];
				let newTab = this.tabs[newValue];
				if (oldTab) oldTab.active = false;
				if (newTab) newTab.active = true;
				this.$emit('tab-change', oldTab, newTab);
				this.$emit('update:startIndex', newValue);
			}
		},
	},
	created() {
		// Not strictly necessary now that we have no button,
		// but left in in case you still rely on it in Vuex
		this.updateBtnText('Next');
	},
};
</script>

<style lang="scss" scoped>
/* Tab content animation */
.tab-content {
	display: flex; // Avoid horizontal scroll when animating
	.tab-pane {
		display: block;
		animation: fadeIn 0.5s;
		width: 100%;
	}
}

.wizard-container {
	width: 100% !important;
}

/* Make sure tab links are always clickable. Removed the "disabled-wizard-link" logic. */
.wizard-navigation .nav-link {
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}

/* Loader */
.lds-circle {
	position: absolute;
}
</style>
