var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bursary
    ? _c("div", { staticClass: "md-layout", attrs: { id: "top" } }, [
        _c(
          "div",
          {
            staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto",
          },
          [
            _c(
              "simple-wizard",
              { attrs: { bursary: _vm.bursary } },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.bursary.title)),
                    ]),
                    _vm.client
                      ? _c(
                          "div",
                          { staticClass: "flex items-center justify-center" },
                          [
                            _c(
                              "h4",
                              { staticStyle: { "margin-right": "2rem" } },
                              [_vm._v("🏢 " + _vm._s(_vm.client.companyName))]
                            ),
                            _c("h4", [
                              _vm._v(
                                "📅 " + _vm._s(_vm.bursary.applicationDeadline)
                              ),
                            ]),
                            _c(
                              "h4",
                              { staticStyle: { "margin-left": "2rem" } },
                              [
                                _vm._v(
                                  "⌛ " +
                                    _vm._s(
                                      _vm.bursaryApplication.applicationStatus
                                    )
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "md-button",
                      {
                        staticClass:
                          "md-simple md-just-icon md-round modal-default-button",
                        staticStyle: {
                          position: "absolute",
                          top: "3px",
                          left: "10px",
                          "font-size": "30px !important",
                        },
                      },
                      [_c("md-icon", [_vm._v("help")])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": function () {
                        return _vm.validateStep("step1")
                      },
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "pc-view" }, [
                        _vm._v("Bursary Details"),
                      ]),
                      _c("div", { staticClass: "mobi-view" }, [
                        _c("i", { staticClass: "fas fa-graduation-cap" }),
                      ]),
                    ]),
                    _c("bursary-details", {
                      attrs: {
                        bursary: _vm.bursary,
                        employer: _vm.client,
                        "bursary-application": _vm.bursaryApplication,
                      },
                    }),
                  ],
                  2
                ),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": function () {
                        return _vm.validateStep("step2")
                      },
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "pc-view" }, [
                        _vm._v("Documents"),
                      ]),
                      _c("div", { staticClass: "mobi-view" }, [
                        _c("i", { staticClass: "fas fa-folder-open" }),
                      ]),
                    ]),
                    _c("submitted-documents"),
                  ],
                  2
                ),
                _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": function () {
                        return _vm.validateStep("step3")
                      },
                    },
                  },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "pc-view" }, [
                        _vm._v("Offer Details"),
                      ]),
                      _c("div", { staticClass: "mobi-view" }, [
                        _c("i", { staticClass: "fas fa-handshake" }),
                      ]),
                    ]),
                    _vm.bursary && _vm.client && _vm.bursaryApplication
                      ? _c("div", { staticStyle: { padding: "2rem" } }, [
                          _vm.bursaryApplication.applicationStatus ===
                          _vm.bursaryApplicationStatus.OFFER_SENT
                            ? _c(
                                "div",
                                [
                                  _c("OfferDetails", {
                                    attrs: {
                                      bursary: _vm.bursary,
                                      client: _vm.client,
                                      bursaryApplication:
                                        _vm.bursaryApplication,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("p", { staticClass: "review-heading" }, [
                                _vm._v(_vm._s(_vm.applicationStatusMessage)),
                              ]),
                          this.bursaryApplication &&
                          this.bursaryApplication.applicationStatus ===
                            _vm.bursaryApplicationStatus.OFFER_ACCEPTED
                            ? _c("p", { staticClass: "review-heading" }, [
                                _vm._v(" Congratulations! 🥳 "),
                                _c("br"),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "20px" } },
                                  [_vm._v(" You have accepted this offer ✅ ")]
                                ),
                              ])
                            : _vm._e(),
                          this.bursaryApplication &&
                          this.bursaryApplication.applicationStatus ===
                            _vm.bursaryApplicationStatus.OFFER_REJECTED
                            ? _c("p", { staticClass: "review-heading" }, [
                                _vm._v(" Sorry 😟 "),
                                _c("br"),
                                _c("br"),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "20px" } },
                                  [_vm._v(" You have rejected this offer ")]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              2
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }