var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "text-center lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.selectedApplicants.length > 0
        ? _c(
            "md-table",
            { attrs: { "md-card": "" } },
            [
              _c("md-table-toolbar", [
                _c(
                  "div",
                  { staticClass: "w-full flex items-center justify-between" },
                  [
                    _c("h1", { staticClass: "md-title" }, [
                      _vm._v("Selected Applicants"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex gap-4" },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-success send-offer-btn",
                            on: { click: _vm.openSendOffersModal },
                          },
                          [_vm._v(" Send Offers ")]
                        ),
                        _c(
                          "md-button",
                          {
                            staticClass: "filter-btn",
                            on: { click: _vm.openFilterMenu },
                          },
                          [_c("md-icon", [_vm._v("filter_alt")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "md-menu",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-icon-button",
                            attrs: { "md-menu-trigger": "" },
                          },
                          [_c("md-icon", [_vm._v("more_vert")])],
                          1
                        ),
                        _c(
                          "md-menu-content",
                          [
                            _c(
                              "md-menu-item",
                              { on: { click: _vm.unselectBulk } },
                              [_vm._v("Unselect Candidates")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "md-table-row",
                [
                  _c(
                    "md-table-head",
                    [
                      _c("md-checkbox", {
                        on: { change: _vm.toggleSelectAll },
                        model: {
                          value: _vm.selectAll,
                          callback: function ($$v) {
                            _vm.selectAll = $$v
                          },
                          expression: "selectAll",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("md-table-head", [_vm._v("Name")]),
                  _c("md-table-head", [_vm._v("Surname")]),
                  _c("md-table-head", [_vm._v("Year of Study")]),
                  _c("md-table-head", [_vm._v("Degree")]),
                  _c("md-table-head", [_vm._v("Status")]),
                  _c("md-table-head"),
                  _c("md-table-head"),
                ],
                1
              ),
              _vm._l(_vm.filteredApplicants, function (applicant) {
                return _c(
                  "md-table-row",
                  { key: applicant.id },
                  [
                    _c(
                      "md-table-cell",
                      [
                        _c("md-checkbox", {
                          attrs: { value: applicant },
                          model: {
                            value: _vm.selectedToRemove,
                            callback: function ($$v) {
                              _vm.selectedToRemove = $$v
                            },
                            expression: "selectedToRemove",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("md-table-cell", [_vm._v(_vm._s(applicant.name))]),
                    _c("md-table-cell", [_vm._v(_vm._s(applicant.surname))]),
                    _c("md-table-cell", [
                      _vm._v(_vm._s(applicant.selectedDegree.yearOfStudy)),
                    ]),
                    _c("md-table-cell", [
                      _vm._v(_vm._s(applicant.selectedDegree.degree)),
                    ]),
                    _c("md-table-cell", [
                      _vm._v(_vm._s(applicant.applicationStatus)),
                    ]),
                    _c(
                      "md-table-cell",
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "view-profile-btn",
                            on: {
                              click: function ($event) {
                                return _vm.openUserProfile(applicant)
                              },
                            },
                          },
                          [
                            _c("md-icon", [_vm._v("person")]),
                            _vm._v(" View Profile "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c("div", { staticClass: "no-applicants-message" }, [
            _c("h3", [_vm._v("No selected applicants found.")]),
            _c("p", [_vm._v("Please select candidates from the shortlist.")]),
          ]),
      _vm.profileModalVisible
        ? _c(
            "modal",
            {
              on: {
                close: function ($event) {
                  _vm.profileModalVisible = false
                },
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass:
                        "md-simple md-just-icon md-round modal-default-button",
                      on: {
                        click: function ($event) {
                          _vm.profileModalVisible = false
                        },
                      },
                    },
                    [
                      _c(
                        "md-icon",
                        {
                          staticStyle: {
                            "font-size": "2rem !important",
                            color: "black !important",
                          },
                        },
                        [_vm._v("clear")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _c("CandidateProfile", {
                    attrs: {
                      userProfile: _vm.selectedApplicant,
                      showFavoriteButton: true,
                      jobId: this.$route.params.id,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.sendOffers
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v(_vm._s(_vm.bursaryInfo.title)),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("h4", [
                        _vm._v(
                          " You are about to send " +
                            _vm._s(
                              _vm.selectedToRemove.length > 1
                                ? "offers to " +
                                    _vm.selectedToRemove.length +
                                    " applicants "
                                : "an offer to " +
                                    _vm.selectedToRemove.length +
                                    " candidate"
                            ) +
                            " "
                        ),
                      ]),
                      _c("h4", { staticClass: "modal-heading" }, [
                        _vm._v("The bursary will cover:"),
                      ]),
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            "margin-top": "-1rem",
                            "text-align": "center",
                          },
                        },
                        _vm._l(_vm.filteredFundingCover, function (item) {
                          return _c("li", { key: item.name }, [
                            _vm._v(_vm._s(item.name) + " ✅"),
                          ])
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-danger",
                          staticStyle: { "margin-right": "2rem !important" },
                          on: { click: _vm.closeSendOffersModal },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.triggerEmailNotifications },
                        },
                        [_vm._v(" Send Offers ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3724992397
            ),
          })
        : _vm._e(),
      _vm.success
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Success! ✅"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.successMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeSuccessModal },
                        },
                        [_vm._v(" Ok ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              334947881
            ),
          })
        : _vm._e(),
      _vm.error
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! 🤚"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [_c("h4", [_vm._v(_vm._s(_vm.errorMessage))])]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeErrorModal },
                        },
                        [_vm._v(" Ok ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3042895156
            ),
          })
        : _vm._e(),
      _c("BursaryApplicantFilter", {
        attrs: {
          visible: _vm.filterMenuVisible,
          filters: _vm.filters,
          uniqueValues: _vm.uniqueValues,
        },
        on: {
          close: _vm.closeFilterMenu,
          apply: _vm.applyFilters,
          reset: _vm.resetFilters,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }