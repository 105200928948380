<template>
	<div>
		<br />
		<br />
		<div class="row">
			<md-icon style="margin-right: 1rem" :class="v$.requirements.$error && 'error'">description</md-icon>
			<label :style="v$.requirements.$error && 'color:red'"> Bursary Requirements </label>
		</div>
		<p class="error_message" v-if="v$.requirements.$error">Requirements are required and should be at least 10 characters long.</p>
		<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="requirements" @text-change="addRequirements" />

		<div style="display: flex; flex-direction: column">
			<div class="row">
				<md-icon :class="v$.documentRequirements.$error && 'error'" style="margin-right: 1rem">folder</md-icon>
				<label :style="v$.documentRequirements.$error && 'color:red'"> Documentation Requirements </label>
			</div>
			<p class="error_message" v-if="v$.documentRequirements.$error">At least one document is required</p>
			<div class="document-option">
				<div style="width: 300px">
					<md-checkbox v-model="cv.isRequired" @change="() => addCv()">CV</md-checkbox>
				</div>
				<md-checkbox v-model="cv.shouldCertify" @change="() => addCvCertification()">Certified</md-checkbox>
			</div>

			<div class="document-option">
				<div style="width: 300px">
					<md-checkbox v-model="id.isRequired" @change="() => addId()">ID</md-checkbox>
				</div>
				<md-checkbox v-model="id.shouldCertify" @change="() => addIdCertification()">Certified</md-checkbox>
			</div>

			<div class="document-option">
				<div style="width: 300px">
					<md-checkbox v-model="proofOfIncome.isRequired" @change="() => addProofOfIncome()">Proof of Income</md-checkbox>
				</div>
				<md-checkbox v-model="proofOfIncome.shouldCertify" @change="() => addProofOfIncomeCertification()">Certified</md-checkbox>
			</div>

			<div class="document-option">
				<div style="width: 300px">
					<md-checkbox v-model="matricResults.isRequired" @change="() => addMatricResults()">Matric Results</md-checkbox>
				</div>
				<md-checkbox v-model="matricResults.shouldCertify" @change="() => addMatricResultsCertification()">Certified</md-checkbox>
			</div>

			<div class="document-option">
				<div style="width: 300px">
					<md-checkbox v-model="academicTranscript.isRequired" @change="() => addAcademicTranscript()">Academic Transcript</md-checkbox>
				</div>
				<md-checkbox v-model="academicTranscript.shouldCertify" @change="() => addAcademicTranscriptCertification()">Certified</md-checkbox>
			</div>

			<div class="document-option">
				<div style="width: 300px">
					<md-checkbox v-model="tuitionQuotation.isRequired" @change="() => addTuitionQuotation()">Tuition Quotation</md-checkbox>
				</div>
				<md-checkbox v-model="tuitionQuotation.shouldCertify" @change="() => addTuitionQuotationCertification()">Certified</md-checkbox>
			</div>

			<div class="document-option">
				<md-checkbox v-model="other.isRequired" @change="() => addOther()">Other</md-checkbox>
			</div>

			<p v-if="other.isRequired && additionalDocuments.length === 0" class="error_message">Please add at least one additional document</p>
		</div>
		<p v-if="additionalDocuments.length > 0">Additional Documents</p>
		<div v-for="(doc, index) in additionalDocuments" :key="index" class="additional-doc">
			<md-field style="width: 265px !important">
				<md-input v-model="doc.name" @change="updateDocument(index, doc.name)" />
			</md-field>
			<md-checkbox style="margin-left: 2rem !important" v-model="doc.shouldCertify" @change="updateDocumentCertification(index)">Certified</md-checkbox>
			<md-button class="md-icon-button md-raised md-primary delete-btn" @click="deleteDocument(index)">
				<md-icon>delete</md-icon>
			</md-button>
		</div>
		<div v-if="other.isRequired">
			<TextField style="width: 30%" :label="'Additional Document'" v-model="newDocument" />
			<md-button class="md-raised md-primary" @click="addDocument"><md-icon>add</md-icon> Add</md-button>
		</div>
		<br />
		<br />
		<div class="row">
			<md-icon style="margin-right: 1rem" :class="v$.documentInstructions.$error && 'error'">description</md-icon>
			<label :style="v$.documentInstructions.$error && 'color:red'"> Document Submission Instructions </label>
		</div>
		<p class="error_message" v-if="v$.documentInstructions.$error">Document submission instructions are required.</p>
		<vue-editor style="margin: 1rem 0 3rem 0 !important" v-model="documentInstructions" @text-change="addDocumentInstructions" />
		<div class="justify-between">
			<md-button class="md-raised" @click="previousStep"><md-icon>arrow_back</md-icon> Previous</md-button>
			<md-button class="md-raised md-primary" @click="nextStep"> Next <md-icon>arrow_forward</md-icon></md-button>
		</div>
	</div>
</template>

<script>
import TextField from '@/components/Inputs/TextField';
import { VueEditor } from 'vue2-editor';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
	components: {
		TextField,
		VueEditor,
	},
	data() {
		return {
			requirements: '',
			cv: { isRequired: false, shouldCertify: false },
			id: { isRequired: false, shouldCertify: false },
			proofOfIncome: { isRequired: false, shouldCertify: false },
			matricResults: { isRequired: false, shouldCertify: false },
			academicTranscript: { isRequired: false, shouldCertify: false },
			tuitionQuotation: { isRequired: false, shouldCertify: false },
			other: { isRequired: false, shouldCertify: false },
			newDocument: '',
			newDocCertified: false,
			additionalDocuments: [],
			documentInstructions: '',
		};
	},
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			requirements: { required },
			documentInstructions: { required },
			documentRequirements: {
				validator: () =>
					this.cv.isRequired ||
					this.id.isRequired ||
					this.proofOfIncome.isRequired ||
					this.matricResults.isRequired ||
					this.academicTranscript.isRequired ||
					this.tuitionQuotation.isRequired ||
					this.other.isRequired,
			},
		};
	},
	methods: {
		nextStep() {
			this.v$.$touch();
			if ((!this.v$.$invalid && !this.other.isRequired) || (this.other.isRequired && this.additionalDocuments.length > 0)) {
				this.$emit('next');
			}
		},
		previousStep() {
			this.$emit('previous', 'requirements', 'description');
		},
		addDocument() {
			if (this.newDocument.trim()) {
				this.additionalDocuments.push({ name: this.newDocument.trim(), shouldCertify: this.newDocCertified });
				this.newDocument = '';
				this.newDocCertified = false;
				this.$emit('addAdditionalDocuments', this.additionalDocuments);
			}
		},
		updateDocument(index, name) {
			this.$set(this.additionalDocuments, index, { ...this.additionalDocuments[index], name: name.trim() });
			this.$emit('addAdditionalDocuments', this.additionalDocuments);
		},
		updateDocumentCertification(index) {
			this.$emit('addAdditionalDocuments', this.additionalDocuments);
		},
		deleteDocument(index) {
			this.additionalDocuments.splice(index, 1);
			this.$emit('addAdditionalDocuments', this.additionalDocuments);
		},
		addCv() {
			this.$emit('cv', this.cv);
		},
		addCvCertification() {
			this.$emit('cv', this.cv);
		},
		addId() {
			this.$emit('id', this.id);
		},
		addIdCertification() {
			this.$emit('id', this.id);
		},
		addRequirements() {
			this.$emit('requirements', this.requirements);
		},
		addProofOfIncome() {
			this.$emit('proofOfIncome', this.proofOfIncome);
		},
		addProofOfIncomeCertification() {
			this.$emit('proofOfIncome', this.proofOfIncome);
		},
		addMatricResults() {
			this.$emit('matricResults', this.matricResults);
		},
		addMatricResultsCertification() {
			this.$emit('matricResults', this.matricResults);
		},
		addAcademicTranscript() {
			this.$emit('academicTranscript', this.academicTranscript);
		},
		addAcademicTranscriptCertification() {
			this.$emit('academicTranscript', this.academicTranscript);
		},
		addTuitionQuotation() {
			this.$emit('tuitionQuotation', this.tuitionQuotation);
		},
		addTuitionQuotationCertification() {
			this.$emit('tuitionQuotation', this.tuitionQuotation);
		},
		addOther() {
			this.$emit('other', this.other);
		},
		addOtherCertification() {
			this.$emit('other', this.other);
		},
		addDocumentInstructions() {
			this.$emit('documentInstructions', this.documentInstructions);
		},
	},
};
</script>

<style scoped>
.delete-btn {
	padding: 5px 10px 5px 10px !important;
}
.document-option {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}
.additional-doc {
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-top: 10px;
	width: 100%;
}
.error_message {
	color: red;
	font-size: 12px;
}
.row {
	display: flex;
	align-items: center;
}
.justify-between {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}
</style>
