<template>
	<div class="bursary-container">
		<!-- Show loading state if data is not yet available -->
		<div v-if="!bursary || !employer" class="background">
			<div class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>

		<!-- Bursary details -->
		<div v-if="bursary && employer" class="bursary-content-container">
			<!-- Company Name -->
			<div class="row">
				<md-icon class="bursary-icon">business</md-icon>
				<h4 class="margin-left-small">Company Name</h4>
			</div>
			<p class="margin-left-medium margin-top-small">{{ employer.companyName }}</p>

			<!-- Bursary Title -->
			<div class="row">
				<md-icon class="bursary-icon">school</md-icon>
				<h4 class="margin-left-small">Bursary Name</h4>
			</div>
			<p class="margin-left-medium margin-top-small">{{ bursary.title }}</p>

			<!-- Description -->
			<div class="row">
				<md-icon class="bursary-icon">description</md-icon>
				<h4 class="margin-left-small">Bursary Description</h4>
			</div>
			<div class="margin-left-medium margin-top-small" v-html="bursary.description"></div>

			<!-- Eligibility -->
			<div class="row">
				<md-icon class="bursary-icon">checklist</md-icon>
				<h4 class="margin-left-small">Eligibility Requirements</h4>
			</div>
			<div class="margin-left-medium margin-top-small" v-html="bursary.bursaryRequirements"></div>

			<!-- Required Documents -->
			<div class="row">
				<md-icon class="bursary-icon">folder</md-icon>
				<h4 class="margin-left-small">Required Documents</h4>
			</div>
			<ul class="margin-left-small margin-top-small">
				<li v-for="(document, index) in hasDocuments()" :key="index">
					{{ formatDocumentName(document) }}
				</li>
			</ul>

			<!-- Additional Cover Details -->
			<div class="row">
				<md-icon class="bursary-icon">description</md-icon>
				<h4 class="margin-left-small">Additional Cover Details</h4>
			</div>
			<div class="margin-left-medium margin-top-small" v-html="bursary.additionalCoverDetails"></div>
		</div>
	</div>
</template>

<script>
import { BursaryStatuses } from '@/constants/bursary-statuses.const';

export default {
	name: 'BursaryDetails',
	components: {},

	props: {
		bursary: Object,
		employer: Object,
		bursaryApplication: Object,
	},

	data() {
		return {
			BursaryStatuses,
		};
	},

	methods: {
		applyForBursary() {
			// Route user to the bursary application wizard
			this.$router.push({
				name: 'bursary-application-wizard',
				params: { id: this.bursary.id },
			});
		},

		hasDocuments() {
			if (!this.bursary.documentationRequirements) return [];
			// Convert object keys to an array, only include those that are true
			return Object.keys(this.bursary.documentationRequirements).filter(doc => this.bursary.documentationRequirements[doc]);
		},

		formatDocumentName(document) {
			// Insert space before capital letters, capitalize the first character
			return document.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
		},

		handleError(message) {
			this.error = true;
			this.errorMessage = message;
		},

		closeErrorModal() {
			this.error = false;
		},
	},
};
</script>

<style scoped>
.bursary-icon {
	font-size: 1.5rem !important;
}

.md-icon {
	align-items: flex-start;
}

.apply-button-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.background {
	width: 100vw !important;
	height: 100vh !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.margin-left-small {
	margin-left: 0.5rem;
}

.margin-left-medium {
	margin-left: 2.2rem;
}

.margin-top-small {
	margin-top: -1.3rem;
}

.row {
	display: flex;
	align-items: center;
}

.md-card .md-card-content {
	padding: 5px;
}
</style>
