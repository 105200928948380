<template>
	<div>
		<div v-if="loading" class="background">
			<div v-if="loading" class="text-center lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<md-table md-card v-if="selectedApplicants.length > 0">
			<md-table-toolbar>
				<div class="w-full flex items-center justify-between">
					<h1 class="md-title">Selected Applicants</h1>
					<div class="flex gap-4">
						<md-button class="md-success send-offer-btn" @click="openSendOffersModal"> Send Offers </md-button>
						<md-button class="filter-btn" @click="openFilterMenu"> <md-icon>filter_alt</md-icon> </md-button>
					</div>
					<md-menu>
						<md-button class="md-icon-button" md-menu-trigger>
							<md-icon>more_vert</md-icon>
						</md-button>
						<md-menu-content>
							<md-menu-item @click="unselectBulk">Unselect Candidates</md-menu-item>
						</md-menu-content>
					</md-menu>
				</div>
			</md-table-toolbar>

			<md-table-row>
				<md-table-head>
					<md-checkbox v-model="selectAll" @change="toggleSelectAll"></md-checkbox>
				</md-table-head>
				<md-table-head>Name</md-table-head>
				<md-table-head>Surname</md-table-head>
				<md-table-head>Year of Study</md-table-head>
				<md-table-head>Degree</md-table-head>
				<md-table-head>Status</md-table-head>
				<md-table-head></md-table-head>
				<md-table-head></md-table-head>
			</md-table-row>

			<md-table-row v-for="applicant in filteredApplicants" :key="applicant.id">
				<md-table-cell>
					<md-checkbox v-model="selectedToRemove" :value="applicant"></md-checkbox>
				</md-table-cell>
				<md-table-cell>{{ applicant.name }}</md-table-cell>
				<md-table-cell>{{ applicant.surname }}</md-table-cell>
				<md-table-cell>{{ applicant.selectedDegree.yearOfStudy }}</md-table-cell>
				<md-table-cell>{{ applicant.selectedDegree.degree }}</md-table-cell>
				<md-table-cell>{{ applicant.applicationStatus }}</md-table-cell>
				<md-table-cell>
					<md-button class="view-profile-btn" @click="openUserProfile(applicant)"> <md-icon>person</md-icon> View Profile </md-button>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<div v-else class="no-applicants-message">
			<h3>No selected applicants found.</h3>
			<p>Please select candidates from the shortlist.</p>
		</div>

		<modal v-if="profileModalVisible" @close="profileModalVisible = false">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalVisible = false">
					<md-icon style="font-size: 2rem !important; color: black !important">clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<CandidateProfile :userProfile="selectedApplicant" :showFavoriteButton="true" :jobId="this.$route.params.id" />
			</template>
		</modal>

		<modal v-if="sendOffers">
			<template #header>
				<h4 class="modal-title black">{{ bursaryInfo.title }}</h4>
			</template>
			<template #body>
				<h4>
					You are about to send
					{{ selectedToRemove.length > 1 ? `offers to ${selectedToRemove.length} applicants ` : `an offer to ${selectedToRemove.length} candidate` }}
				</h4>
				<h4 class="modal-heading">The bursary will cover:</h4>
				<ul style="margin-top: -1rem; text-align: center">
					<li v-for="item in filteredFundingCover" :key="item.name">{{ item.name }} ✅</li>
				</ul>
			</template>
			<template #footer>
				<md-button class="md-danger" style="margin-right: 2rem !important" @click="closeSendOffersModal"> Cancel </md-button>
				<md-button class="md-success" @click="triggerEmailNotifications"> Send Offers </md-button>
			</template>
		</modal>

		<modal v-if="success">
			<template #header>
				<h4 class="modal-title black">Success! ✅</h4>
			</template>
			<template #body>
				<h4>{{ successMessage }}</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeSuccessModal"> Ok </md-button>
			</template>
		</modal>
		<modal v-if="error">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>{{ errorMessage }}</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
		<BursaryApplicantFilter :visible="filterMenuVisible" :filters="filters" :uniqueValues="uniqueValues" @close="closeFilterMenu" @apply="applyFilters" @reset="resetFilters" />
	</div>
</template>

<script>
import { doc, getDoc, updateDoc, collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import db from '@/firebase/init';
import { BursaryStatuses } from '../../../../../../constants/bursary-statuses.const';
import CandidateProfile from '@/components/Profile/CandidateProfile.vue';
import { Modal } from '@/components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { emailTemplates } from '../../../../../../constants/email-template.const';
import BursaryApplicantFilter from '@/components/Filters/BursaryApplicantFilter.vue';
import { sendEmailNotification } from '@/functions/notifications';

export default {
	components: { CandidateProfile, Modal, BursaryApplicantFilter },
	data() {
		return {
			sendOffers: false,
			success: false,
			loading: false,
			successMessage: '',
			selectedApplicants: [],
			selectedToRemove: [],
			selectAll: false,
			unsubscribe: null,
			profileModalVisible: false,
			selectedApplicant: null,
			error: false,
			errorMessage: '',
			bursaryInfo: {},
			filterMenuVisible: false,
			filters: {
				gender: null,
				degree: null,
				yearOfStudy: null,
				qualificationType: null,
				ethnicity: null,
				university: null,
				status: null,
				favourite: false,
			},
			uniqueValues: {
				gender: [],
				degree: [],
				yearOfStudy: [],
				qualificationType: [],
				ethnicity: [],
				university: [],
				status: [],
				favourite: [],
			},
		};
	},
	created() {
		this.subscribeToSelectedApplicants();
		this.fetchBursaryInformation();
	},
	beforeUnmount() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	},
	computed: {
		filteredFundingCover() {
			if (!this.bursaryInfo || !this.bursaryInfo.fundingCover) return [];

			return Object.entries(this.bursaryInfo.fundingCover)
				.filter(([_, value]) => value) // Only keep entries where value === true
				.map(([key, value]) => ({
					name: key.charAt(0).toUpperCase() + key.slice(1),
					value,
				}));
		},
		filteredApplicants() {
			let filtered = this.selectedApplicants;
			if (this.filters.gender) {
				filtered = filtered.filter(applicant => applicant.gender === this.filters.gender);
			}

			if (this.filters.favourite) {
				filtered = filtered.filter(applicant => applicant.favourite.toString() === this.filters.favourite.toString());
			}

			if (this.filters.degree) {
				filtered = filtered.filter(applicant => applicant.selectedDegree?.degree === this.filters.degree);
			}

			if (this.filters.yearOfStudy) {
				filtered = filtered.filter(applicant => applicant.selectedDegree?.yearOfStudy === this.filters.yearOfStudy);
			}

			if (this.filters.qualificationType) {
				filtered = filtered.filter(applicant => applicant.selectedDegree?.qualificationType === this.filters.qualificationType);
			}

			if (this.filters.ethnicity) {
				filtered = filtered.filter(applicant => applicant.ethnicity === this.filters.ethnicity);
			}

			if (this.filters.university) {
				filtered = filtered.filter(applicant => applicant.selectedDegree?.university === this.filters.university);
			}

			if (this.filters.status) {
				filtered = filtered.filter(applicant => applicant.status === this.filters.status);
			}
			return filtered;
		},
	},

	methods: {
		openFilterMenu() {
			this.filterMenuVisible = true;
		},
		closeFilterMenu() {
			this.filterMenuVisible = false;
		},
		applyFilters() {
			this.filterMenuVisible = false;
		},
		resetFilters() {
			this.filters = {
				gender: null,
				degree: null,
				yearOfStudy: null,
				qualificationType: null,
				ethnicity: null,
				university: null,
				status: null,
				favourite: false,
			};
		},
		openSendOffersModal() {
			if (this.selectedToRemove.length === 0) {
				this.displayError('Please select at least one applicant to send an offer.');
				return;
			}
			this.sendOffers = true;
		},
		closeSendOffersModal() {
			this.sendOffers = false;
		},
		async fetchBursaryInformation() {
			try {
				this.loading = true;
				const bursaryId = this.$route.params.bursaryId;
				if (!bursaryId) return this.displayError('Bursary ID is missing.');

				const docSnap = await getDoc(doc(db, 'bursaries', bursaryId));
				this.bursaryInfo = docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
				if (!this.bursaryInfo) this.displayError('⚠️ No bursary found.');
			} catch (error) {
				console.error('Error fetching bursary:', error);
			} finally {
				this.loading = false;
			}
		},

		async triggerEmailNotifications() {
			if (!this.selectedToRemove.length) return this.displayError('Select at least one applicant.');
			if (!this.bursaryInfo) return this.displayError('Bursary info unavailable.');

			try {
				this.loading = true;

				const fundingCoverDetails =
					Object.keys(this.bursaryInfo.fundingCover || {})
						.filter(key => this.bursaryInfo.fundingCover[key])
						.map(key => key.charAt(0).toUpperCase() + key.slice(1))
						.join(', ') || 'Not Specified';

				const emailPayload = this.selectedToRemove.map(({ email, name, surname, selectedDegree }) => ({
					useTemplate: true,
					templateId: emailTemplates.C1,
					to: email,
					dynamicTemplateData: {
						studentName: `${name} ${surname}`,
						bursaryTitle: this.bursaryInfo.title,
						yearOfStudy: selectedDegree.yearOfStudy,
						degree: selectedDegree.degree,
						university: selectedDegree.university || 'Not Provided',
						fundingCover: fundingCoverDetails,
					},
				}));
				await sendEmailNotification('notification-sendEmailNotification', emailPayload);

				await Promise.all(
					this.selectedToRemove.map(({ id }) =>
						updateDoc(doc(db, 'bursary-applications', id), {
							applicationStatus: BursaryStatuses.OFFER_SENT,
						})
					)
				);

				this.success = true;
				this.successMessage = `Successfully sent offers to ${this.selectedToRemove.length} applicant(s).`;
				this.selectedToRemove = [];
				this.selectAll = this.sendOffers = false;
			} catch (error) {
				this.displayError('Error sending offers.');
				this.success = false;
			} finally {
				this.loading = false;
			}
		},
		async subscribeToSelectedApplicants() {
			try {
				const bursaryId = this.$route.params.bursaryId;
				const selectedQuery = query(collection(db, 'bursary-applications'), where('bursaryID', '==', bursaryId), where('status', '==', BursaryStatuses.SELECTED));

				this.unsubscribe = onSnapshot(selectedQuery, async snapshot => {
					const bursaryApplicants = snapshot.docs.map(doc => ({
						...doc.data(),
						id: doc.id,
					}));
					if (bursaryApplicants.length === 0) {
						this.selectedApplicants = [];
						this.populateUniqueFilterValues();
						return;
					}
					const studentQueries = bursaryApplicants.map(async applicant => {
						const studentQuery = query(collection(db, 'students'), where('userId', '==', applicant.userId));
						const studentSnapshot = await getDocs(studentQuery);
						if (!studentSnapshot.empty) {
							const studentData = studentSnapshot.docs[0].data();
							return {
								...applicant,
								gender: studentData.gender || 'Unknown',
								ethnicity: studentData.race || 'Unknown',
							};
						}
						return {
							...applicant,
							gender: 'Unknown',
							ethnicity: 'Unknown',
						};
					});
					this.selectedApplicants = await Promise.all(studentQueries);
					this.populateUniqueFilterValues();
				});
			} catch (error) {
				this.displayError('Error fetching selected applicants:', error);
			}
		},
		populateUniqueFilterValues() {
			this.uniqueValues.gender = [...new Set(this.selectedApplicants.map(applicant => applicant.gender || 'Unknown'))];
			this.uniqueValues.favourite = [...new Set(this.selectedApplicants.map(applicant => applicant.favourite?.toString() || 'false'))];
			this.uniqueValues.qualificationType = [...new Set(this.selectedApplicants.map(applicant => applicant.selectedDegree?.qualificationType || 'N/A'))];
			this.uniqueValues.ethnicity = [...new Set(this.selectedApplicants.map(applicant => applicant.ethnicity || 'Unknown'))];
			this.uniqueValues.degree = [...new Set(this.selectedApplicants.map(applicant => applicant.selectedDegree?.degree || 'N/A'))];
			this.uniqueValues.yearOfStudy = [...new Set(this.selectedApplicants.map(applicant => applicant.selectedDegree?.yearOfStudy?.toString() || 'N/A'))];
			this.uniqueValues.university = [...new Set(this.selectedApplicants.map(applicant => applicant.selectedDegree?.university || 'N/A'))];
			this.uniqueValues.status = [...new Set(this.selectedApplicants.map(applicant => applicant.status || 'Unknown'))];
		},

		async openUserProfile(applicant) {
			try {
				const docRef = doc(db, 'bursary-applications', applicant.id);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					this.selectedApplicant = { id: applicant.id, ...docSnap.data(), alias: docSnap.data().studentAlias };
				} else {
					this.selectedApplicant = { ...applicant };
				}

				this.profileModalVisible = true;
			} catch (error) {
				this.displayError('Error fetching applicant details.');
			}
		},

		async unselectApplicant(applicant) {
			if (!applicant.id) {
				this.displayError('Missing document ID for the applicant.');
				return;
			}

			const docRef = doc(db, 'bursary-applications', applicant.id);

			try {
				await updateDoc(docRef, { status: BursaryStatuses.UNSELECTED });
			} catch (error) {
				this.displayError('An error occurred while updating the applicant status.');
			}
		},

		async unselectBulk() {
			if (this.selectedToRemove.length === 0) {
				this.displayError('Please select at least one applicant to unselect.');
				return;
			}

			try {
				const updatePromises = this.selectedToRemove.map(applicant => {
					if (!applicant.id) {
						this.displayError('One or more applicants are missing document IDs.');
						return Promise.resolve();
					}
					const docRef = doc(db, 'bursary-applications', applicant.id);
					return updateDoc(docRef, { status: BursaryStatuses.UNSELECTED });
				});

				await Promise.all(updatePromises);
				this.selectedToRemove = [];
				this.selectAll = false;
			} catch (error) {
				this.displayError('An error occurred while bulk unselecting applicants.');
			}
		},

		toggleSelectAll() {
			if (this.selectAll) {
				this.selectedToRemove = [...this.selectedApplicants];
			} else {
				this.selectedToRemove = [];
			}
		},

		displayError(message) {
			this.errorMessage = message;
			this.error = true;
		},

		closeErrorModal() {
			this.error = false;
			this.errorMessage = '';
		},
		closeSuccessModal() {
			this.success = false;
			this.successMessage = '';
		},
	},
};
</script>

<style scoped>
.md-menu {
	margin-left: 2rem !important;
}
.md-menu > button {
	height: 40px;
}
.filter-btn {
	background-color: #558fe6 !important;
}

.filter-btn:hover {
	background-color: #4384e6 !important;
}
.modal-heading {
	text-align: left;
	font-weight: bold;
}
li {
	text-align: left;
}
p {
	text-align: left;
}
.send-offer-btn {
	margin-right: 2rem !important;
}
.md-title {
	font-size: 1.5rem !important;
}
.md-table {
	overflow: auto;
}
.md-warn {
	background-color: #e74c3c !important;
	color: white !important;
}
.md-warn:hover {
	background-color: #c0392b !important;
}
.md-checkbox {
	margin: 0 !important;
}
.no-applicants-message {
	text-align: center;
	padding: 2rem;
	color: #666;
}
.no-applicants-message h3 {
	font-size: 1.5rem;
	margin-bottom: 0.5rem;
}
.unselect-btn {
	width: fit-content !important;
}
.view-profile-btn {
	background-color: #4caf50 !important;
	color: white !important;
	width: fit-content !important;
	padding-bottom: 30px !important;
}
.view-profile-btn:hover {
	background-color: #388e3c !important;
}

.remove-btn {
	background-color: white;
	color: #d94b31 !important;
	cursor: pointer;
	outline: none;
	border: 1px solid #d94b31;
	border-radius: 5px;
}

.remove-icon {
	color: #d94b31 !important;
}

.unselect-btn {
	background-color: #e23927 !important;

	border: 1px solid #e74c3c !important;
}
.unselect-btn:hover {
	background-color: #e74c3c !important;
	color: white !important;
}

.view-profile-btn {
	background-color: #4caf50 !important;
	color: white !important;
}
.view-profile-btn:hover {
	background-color: #388e3c !important;
}
</style>
