export const BursaryStatuses = {
	PENDING: 'Pending',
	APPLIED: 'Applied',
	SELECTED: 'Selected',
	IN_PROGRESS: 'In Progress',
	UNSELECTED: 'Unselected',
	OFFER_SENT: 'Offer Sent',
	OFFER_ACCEPTED: 'Offer Accepted',
	OFFER_REJECTED: 'Offer Rejected',
};
