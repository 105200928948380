var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "md-icon",
            {
              class: _vm.v$.requirements.$error && "error",
              staticStyle: { "margin-right": "1rem" },
            },
            [_vm._v("description")]
          ),
          _c("label", { style: _vm.v$.requirements.$error && "color:red" }, [
            _vm._v(" Bursary Requirements "),
          ]),
        ],
        1
      ),
      _vm.v$.requirements.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v(
              "Requirements are required and should be at least 10 characters long."
            ),
          ])
        : _vm._e(),
      _c("vue-editor", {
        staticStyle: { margin: "1rem 0 3rem 0 !important" },
        on: { "text-change": _vm.addRequirements },
        model: {
          value: _vm.requirements,
          callback: function ($$v) {
            _vm.requirements = $$v
          },
          expression: "requirements",
        },
      }),
      _c(
        "div",
        { staticStyle: { display: "flex", "flex-direction": "column" } },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "md-icon",
                {
                  class: _vm.v$.documentRequirements.$error && "error",
                  staticStyle: { "margin-right": "1rem" },
                },
                [_vm._v("folder")]
              ),
              _c(
                "label",
                { style: _vm.v$.documentRequirements.$error && "color:red" },
                [_vm._v(" Documentation Requirements ")]
              ),
            ],
            1
          ),
          _vm.v$.documentRequirements.$error
            ? _c("p", { staticClass: "error_message" }, [
                _vm._v("At least one document is required"),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "div",
                { staticStyle: { width: "300px" } },
                [
                  _c(
                    "md-checkbox",
                    {
                      on: {
                        change: function () {
                          return _vm.addCv()
                        },
                      },
                      model: {
                        value: _vm.cv.isRequired,
                        callback: function ($$v) {
                          _vm.$set(_vm.cv, "isRequired", $$v)
                        },
                        expression: "cv.isRequired",
                      },
                    },
                    [_vm._v("CV")]
                  ),
                ],
                1
              ),
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addCvCertification()
                    },
                  },
                  model: {
                    value: _vm.cv.shouldCertify,
                    callback: function ($$v) {
                      _vm.$set(_vm.cv, "shouldCertify", $$v)
                    },
                    expression: "cv.shouldCertify",
                  },
                },
                [_vm._v("Certified")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "div",
                { staticStyle: { width: "300px" } },
                [
                  _c(
                    "md-checkbox",
                    {
                      on: {
                        change: function () {
                          return _vm.addId()
                        },
                      },
                      model: {
                        value: _vm.id.isRequired,
                        callback: function ($$v) {
                          _vm.$set(_vm.id, "isRequired", $$v)
                        },
                        expression: "id.isRequired",
                      },
                    },
                    [_vm._v("ID")]
                  ),
                ],
                1
              ),
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addIdCertification()
                    },
                  },
                  model: {
                    value: _vm.id.shouldCertify,
                    callback: function ($$v) {
                      _vm.$set(_vm.id, "shouldCertify", $$v)
                    },
                    expression: "id.shouldCertify",
                  },
                },
                [_vm._v("Certified")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "div",
                { staticStyle: { width: "300px" } },
                [
                  _c(
                    "md-checkbox",
                    {
                      on: {
                        change: function () {
                          return _vm.addProofOfIncome()
                        },
                      },
                      model: {
                        value: _vm.proofOfIncome.isRequired,
                        callback: function ($$v) {
                          _vm.$set(_vm.proofOfIncome, "isRequired", $$v)
                        },
                        expression: "proofOfIncome.isRequired",
                      },
                    },
                    [_vm._v("Proof of Income")]
                  ),
                ],
                1
              ),
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addProofOfIncomeCertification()
                    },
                  },
                  model: {
                    value: _vm.proofOfIncome.shouldCertify,
                    callback: function ($$v) {
                      _vm.$set(_vm.proofOfIncome, "shouldCertify", $$v)
                    },
                    expression: "proofOfIncome.shouldCertify",
                  },
                },
                [_vm._v("Certified")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "div",
                { staticStyle: { width: "300px" } },
                [
                  _c(
                    "md-checkbox",
                    {
                      on: {
                        change: function () {
                          return _vm.addMatricResults()
                        },
                      },
                      model: {
                        value: _vm.matricResults.isRequired,
                        callback: function ($$v) {
                          _vm.$set(_vm.matricResults, "isRequired", $$v)
                        },
                        expression: "matricResults.isRequired",
                      },
                    },
                    [_vm._v("Matric Results")]
                  ),
                ],
                1
              ),
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addMatricResultsCertification()
                    },
                  },
                  model: {
                    value: _vm.matricResults.shouldCertify,
                    callback: function ($$v) {
                      _vm.$set(_vm.matricResults, "shouldCertify", $$v)
                    },
                    expression: "matricResults.shouldCertify",
                  },
                },
                [_vm._v("Certified")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "div",
                { staticStyle: { width: "300px" } },
                [
                  _c(
                    "md-checkbox",
                    {
                      on: {
                        change: function () {
                          return _vm.addAcademicTranscript()
                        },
                      },
                      model: {
                        value: _vm.academicTranscript.isRequired,
                        callback: function ($$v) {
                          _vm.$set(_vm.academicTranscript, "isRequired", $$v)
                        },
                        expression: "academicTranscript.isRequired",
                      },
                    },
                    [_vm._v("Academic Transcript")]
                  ),
                ],
                1
              ),
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addAcademicTranscriptCertification()
                    },
                  },
                  model: {
                    value: _vm.academicTranscript.shouldCertify,
                    callback: function ($$v) {
                      _vm.$set(_vm.academicTranscript, "shouldCertify", $$v)
                    },
                    expression: "academicTranscript.shouldCertify",
                  },
                },
                [_vm._v("Certified")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "div",
                { staticStyle: { width: "300px" } },
                [
                  _c(
                    "md-checkbox",
                    {
                      on: {
                        change: function () {
                          return _vm.addTuitionQuotation()
                        },
                      },
                      model: {
                        value: _vm.tuitionQuotation.isRequired,
                        callback: function ($$v) {
                          _vm.$set(_vm.tuitionQuotation, "isRequired", $$v)
                        },
                        expression: "tuitionQuotation.isRequired",
                      },
                    },
                    [_vm._v("Tuition Quotation")]
                  ),
                ],
                1
              ),
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addTuitionQuotationCertification()
                    },
                  },
                  model: {
                    value: _vm.tuitionQuotation.shouldCertify,
                    callback: function ($$v) {
                      _vm.$set(_vm.tuitionQuotation, "shouldCertify", $$v)
                    },
                    expression: "tuitionQuotation.shouldCertify",
                  },
                },
                [_vm._v("Certified")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "document-option" },
            [
              _c(
                "md-checkbox",
                {
                  on: {
                    change: function () {
                      return _vm.addOther()
                    },
                  },
                  model: {
                    value: _vm.other.isRequired,
                    callback: function ($$v) {
                      _vm.$set(_vm.other, "isRequired", $$v)
                    },
                    expression: "other.isRequired",
                  },
                },
                [_vm._v("Other")]
              ),
            ],
            1
          ),
          _vm.other.isRequired && _vm.additionalDocuments.length === 0
            ? _c("p", { staticClass: "error_message" }, [
                _vm._v("Please add at least one additional document"),
              ])
            : _vm._e(),
        ]
      ),
      _vm.additionalDocuments.length > 0
        ? _c("p", [_vm._v("Additional Documents")])
        : _vm._e(),
      _vm._l(_vm.additionalDocuments, function (doc, index) {
        return _c(
          "div",
          { key: index, staticClass: "additional-doc" },
          [
            _c(
              "md-field",
              { staticStyle: { width: "265px !important" } },
              [
                _c("md-input", {
                  on: {
                    change: function ($event) {
                      return _vm.updateDocument(index, doc.name)
                    },
                  },
                  model: {
                    value: doc.name,
                    callback: function ($$v) {
                      _vm.$set(doc, "name", $$v)
                    },
                    expression: "doc.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "md-checkbox",
              {
                staticStyle: { "margin-left": "2rem !important" },
                on: {
                  change: function ($event) {
                    return _vm.updateDocumentCertification(index)
                  },
                },
                model: {
                  value: doc.shouldCertify,
                  callback: function ($$v) {
                    _vm.$set(doc, "shouldCertify", $$v)
                  },
                  expression: "doc.shouldCertify",
                },
              },
              [_vm._v("Certified")]
            ),
            _c(
              "md-button",
              {
                staticClass: "md-icon-button md-raised md-primary delete-btn",
                on: {
                  click: function ($event) {
                    return _vm.deleteDocument(index)
                  },
                },
              },
              [_c("md-icon", [_vm._v("delete")])],
              1
            ),
          ],
          1
        )
      }),
      _vm.other.isRequired
        ? _c(
            "div",
            [
              _c("TextField", {
                staticStyle: { width: "30%" },
                attrs: { label: "Additional Document" },
                model: {
                  value: _vm.newDocument,
                  callback: function ($$v) {
                    _vm.newDocument = $$v
                  },
                  expression: "newDocument",
                },
              }),
              _c(
                "md-button",
                {
                  staticClass: "md-raised md-primary",
                  on: { click: _vm.addDocument },
                },
                [_c("md-icon", [_vm._v("add")]), _vm._v(" Add")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "md-icon",
            {
              class: _vm.v$.documentInstructions.$error && "error",
              staticStyle: { "margin-right": "1rem" },
            },
            [_vm._v("description")]
          ),
          _c(
            "label",
            { style: _vm.v$.documentInstructions.$error && "color:red" },
            [_vm._v(" Document Submission Instructions ")]
          ),
        ],
        1
      ),
      _vm.v$.documentInstructions.$error
        ? _c("p", { staticClass: "error_message" }, [
            _vm._v("Document submission instructions are required."),
          ])
        : _vm._e(),
      _c("vue-editor", {
        staticStyle: { margin: "1rem 0 3rem 0 !important" },
        on: { "text-change": _vm.addDocumentInstructions },
        model: {
          value: _vm.documentInstructions,
          callback: function ($$v) {
            _vm.documentInstructions = $$v
          },
          expression: "documentInstructions",
        },
      }),
      _c(
        "div",
        { staticClass: "justify-between" },
        [
          _c(
            "md-button",
            { staticClass: "md-raised", on: { click: _vm.previousStep } },
            [_c("md-icon", [_vm._v("arrow_back")]), _vm._v(" Previous")],
            1
          ),
          _c(
            "md-button",
            {
              staticClass: "md-raised md-primary",
              on: { click: _vm.nextStep },
            },
            [_vm._v(" Next "), _c("md-icon", [_vm._v("arrow_forward")])],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }