var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "md-card",
    { staticClass: "offer-details-container" },
    [
      _c("div", { staticClass: "offer-header" }, [
        _c(
          "div",
          { staticClass: "offer-info" },
          [
            _c("md-icon", [_vm._v(" emoji_events ")]),
            _c("h4", { staticClass: "offer-details-heading" }, [
              _vm._v("Sponsor"),
            ]),
          ],
          1
        ),
        _c("p", { staticClass: "offer-text" }, [
          _vm._v(_vm._s(_vm.client.companyName)),
        ]),
      ]),
      _c("md-divider"),
      _c(
        "div",
        {
          staticClass: "w-full flex items-center justify-between",
          staticStyle: { "flex-wrap": "wrap" },
        },
        [
          _c("div", { staticClass: "offer-header" }, [
            _c(
              "div",
              { staticClass: "offer-info" },
              [
                _c("md-icon", [_vm._v(" school ")]),
                _c("h4", { staticClass: "offer-details-heading" }, [
                  _vm._v("Institution"),
                ]),
              ],
              1
            ),
            _c("p", { staticClass: "offer-text" }, [
              _vm._v(_vm._s(_vm.bursaryApplication.selectedDegree.university)),
            ]),
          ]),
          _c("div", { staticClass: "offer-header" }, [
            _c(
              "div",
              { staticClass: "offer-info" },
              [
                _c("md-icon", [_vm._v(" book ")]),
                _c("h4", { staticClass: "offer-details-heading" }, [
                  _vm._v("Degree"),
                ]),
              ],
              1
            ),
            _c("p", { staticClass: "offer-text" }, [
              _vm._v(_vm._s(_vm.bursaryApplication.selectedDegree.degree)),
            ]),
          ]),
          _c("div", { staticClass: "offer-header" }, [
            _c(
              "div",
              { staticClass: "offer-info" },
              [
                _c("md-icon", [_vm._v(" calendar_month ")]),
                _c("h4", { staticClass: "offer-details-heading" }, [
                  _vm._v("Academic Year"),
                ]),
              ],
              1
            ),
            _c("p", { staticClass: "offer-text" }, [
              _vm._v(_vm._s(_vm.bursaryApplication.selectedDegree.yearOfStudy)),
            ]),
          ]),
        ]
      ),
      _c("md-divider"),
      _c("div", { staticClass: "offer-coverage" }, [
        _c(
          "div",
          { staticClass: "offer-info" },
          [
            _c("md-icon", [_vm._v(" school ")]),
            _c("h4", { staticClass: "offer-details-heading" }, [
              _vm._v("Coverage Details"),
            ]),
          ],
          1
        ),
        _c(
          "ul",
          { staticClass: "coverage-list" },
          _vm._l(_vm.filteredFundingCover, function (value, key) {
            return _c("li", { key: key }, [
              _vm._v(" " + _vm._s(_vm.formatFundingKey(key)) + " "),
            ])
          }),
          0
        ),
      ]),
      _vm.offer.status === "Pending"
        ? _c(
            "div",
            { staticClass: "offer-actions" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-success",
                  staticStyle: { "margin-right": "2rem" },
                  attrs: { disabled: _vm.offer.status !== "Pending" },
                  on: {
                    click: function ($event) {
                      _vm.showAcceptanceModal = true
                    },
                  },
                },
                [_vm._v(" Accept Offer ")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-danger",
                  attrs: { disabled: _vm.offer.status !== "Pending" },
                  on: {
                    click: function ($event) {
                      _vm.showRejectionModal = true
                    },
                  },
                },
                [_vm._v(" Reject Offer ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showRejectionModal
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title" }, [
                        _vm._v("You're about to reject an offer 😟"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", { staticClass: "modal-description" }, [
                        _vm._v(
                          "Please let us know why you're declining this offer."
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "radio-container" },
                        [
                          _c(
                            "md-radio",
                            {
                              staticStyle: { color: "black !important" },
                              attrs: { value: "Another bursary accepted" },
                              model: {
                                value: _vm.reason,
                                callback: function ($$v) {
                                  _vm.reason = $$v
                                },
                                expression: "reason",
                              },
                            },
                            [_vm._v("Another bursary accepted")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio-container" },
                        [
                          _c(
                            "md-radio",
                            {
                              staticStyle: { color: "black !important" },
                              attrs: { value: "No longer interested" },
                              model: {
                                value: _vm.reason,
                                callback: function ($$v) {
                                  _vm.reason = $$v
                                },
                                expression: "reason",
                              },
                            },
                            [_vm._v("No longer interested")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "radio-container" },
                        [
                          _c(
                            "md-radio",
                            {
                              staticStyle: { color: "black !important" },
                              attrs: { value: "Other" },
                              model: {
                                value: _vm.reason,
                                callback: function ($$v) {
                                  _vm.reason = $$v
                                },
                                expression: "reason",
                              },
                            },
                            [_vm._v("Other")]
                          ),
                        ],
                        1
                      ),
                      _vm.reason === "Other"
                        ? _c(
                            "div",
                            { staticClass: "other-reason-container" },
                            [
                              _c(
                                "md-field",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "other-reason" } },
                                    [_vm._v("Please specify")]
                                  ),
                                  _c("md-input", {
                                    attrs: { id: "other-reason" },
                                    model: {
                                      value: _vm.otherReason,
                                      callback: function ($$v) {
                                        _vm.otherReason = $$v
                                      },
                                      expression: "otherReason",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-danger",
                          staticStyle: { "margin-right": "1rem" },
                          on: {
                            click: function ($event) {
                              _vm.showRejectionModal = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.submitRejection },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2373769366
            ),
          })
        : _vm._e(),
      _vm.showAcceptanceModal
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h3", { staticStyle: { color: "black" } }, [
                        _vm._v("Accept Bursary Offer"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", { staticClass: "modal-description" }, [
                        _vm._v(
                          " You are about to accept the bursary offer from "
                        ),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(_vm._s(_vm.client.companyName)),
                        ]),
                        _vm._v(" for "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(_vm.bursaryApplication.selectedDegree.degree)
                          ),
                        ]),
                        _vm._v(" at "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.bursaryApplication.selectedDegree.university
                            )
                          ),
                        ]),
                        _vm._v(" for "),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.bursaryApplication.selectedDegree.yearOfStudy
                            )
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-danger",
                          staticStyle: { "margin-right": "1rem" },
                          on: {
                            click: function ($event) {
                              _vm.showAcceptanceModal = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.acceptOffer },
                        },
                        [_vm._v("Submit")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2695352332
            ),
          })
        : _vm._e(),
      _vm.bursaryOfferAccepted
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h3", { staticStyle: { color: "black" } }, [
                        _vm._v("Congratulations! 🥳"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", { staticClass: "modal-description" }, [
                        _vm._v("You have accepted this bursary offer ✅"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: {
                            click: function ($event) {
                              _vm.bursaryOfferAccepted = false
                            },
                          },
                        },
                        [_vm._v("Okay")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2344879280
            ),
          })
        : _vm._e(),
      _vm.showRejectionAcceptanceModal
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h3", { staticStyle: { color: "black" } }, [
                        _vm._v("😟"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("p", { staticClass: "modal-description" }, [
                        _vm._v(
                          "You have rejected this bursary offer, sorry to see you go"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: {
                            click: function ($event) {
                              _vm.showRejectionAcceptanceModal = false
                            },
                          },
                        },
                        [_vm._v("Okay")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2634870504
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }