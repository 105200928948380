<template>
	<div>
		<div class="md-layout">
			<md-card>
				<md-card-content>
					<collapse :collapse="['Description', 'Requirements', 'Funding Cover', 'Additional Submissions']" icon="keyboard_arrow_down" color-collapse="success">
						<template slot="md-collapse-pane-1">
							<md-card class="bg-success">
								<md-card-content>
									<div class="row">
										<md-icon class="bursary-icon">school</md-icon>
										<h4 class="margin-left-small">Bursary Name</h4>
									</div>
									<p class="margin-left-medium margin-top-small">{{ title }}</p>

									<div class="row">
										<md-icon class="bursary-icon">description</md-icon>
										<h4 class="margin-left-small">Bursary Description</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="description"></div>

									<div class="row">
										<md-icon class="bursary-icon">checklist</md-icon>
										<h4 class="margin-left-small">Eligibility Requirements</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="requirements"></div>

									<div class="row">
										<md-icon class="bursary-icon">folder</md-icon>
										<h4 class="margin-left-small">Required Documents</h4>
									</div>
									<ul class="margin-left-small margin-top-small">
										<li v-if="cv.isRequired">CV <span v-if="cv.shouldCertify">(Certified)</span></li>
										<li v-if="id.isRequired">ID <span v-if="id.shouldCertify">(Certified)</span></li>
										<li v-if="proofOfIncome.isRequired">Proof of Income <span v-if="proofOfIncome.shouldCertify">(Certified)</span></li>
										<li v-if="matricResults.isRequired">Matric Results <span v-if="matricResults.shouldCertify">(Certified)</span></li>
										<li v-if="academicTranscript.isRequired">Academic Transcript <span v-if="academicTranscript.shouldCertify">(Certified)</span></li>
										<li v-if="tuitionQuotation.isRequired">Tuition Quotation <span v-if="tuitionQuotation.shouldCertify">(Certified)</span></li>
										<li v-if="other.isRequired">Other <span v-if="other.shouldCertify">(Certified)</span></li>
									</ul>

									<div class="row" v-if="additionalDocuments && additionalDocuments.length > 0">
										<md-icon class="bursary-icon">folder</md-icon>
										<h4 class="margin-left-small">Additional Documents</h4>
									</div>
									<ul class="margin-left-small margin-top-small">
										<li v-for="(document, index) in additionalDocuments" :key="index">
											{{ document.name }} <span v-if="document.shouldCertify">(Certified)</span>
										</li>
									</ul>

									<div class="row">
										<md-icon class="bursary-icon">info</md-icon>
										<h4 class="margin-left-small">Document Submission Instructions</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="documentInstructions"></div>

									<div class="row">
										<md-icon class="bursary-icon">school</md-icon>
										<h4 class="margin-left-small">Funding Cover</h4>
									</div>
									<ul style="margin-top: -1rem">
										<li>Tuition: {{ tuition ? '✅' : '❌' }}</li>
										<li>Accommodation: {{ accommodation ? '✅' : '❌' }}</li>
										<li>Meals: {{ meals ? '✅' : '❌' }}</li>
										<li>Books: {{ books ? '✅' : '❌' }}</li>
										<li>Stipend: {{ stipend ? '✅' : '❌' }}</li>
									</ul>

									<div class="row">
										<md-icon class="bursary-icon">description</md-icon>
										<h4 class="margin-left-small">Additional Cover Details</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="additionalCoverDetails"></div>

									<div class="row">
										<md-icon class="bursary-icon">place</md-icon>
										<h4 class="margin-left-small">Work Placement Information</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="workPlacementInfo"></div>

									<div class="row" v-show="videoSubmission === 'Yes'">
										<md-icon class="bursary-icon">videocam</md-icon>
										<h4 class="margin-left-small">Video Submission Details</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-show="videoSubmission === 'Yes'" v-html="videoSubmissionDetails"></div>

									<div class="row" v-show="writtenSubmission === 'Yes'">
										<md-icon class="bursary-icon">assignment</md-icon>
										<h4 class="margin-left-small">Written Submission Details</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-show="writtenSubmission === 'Yes'" v-html="writtenSubmissionDetails"></div>
								</md-card-content>
							</md-card>
						</template>
					</collapse>
				</md-card-content>
			</md-card>

			<div class="justify-between">
				<md-button class="md-raised" @click="previousStep"><md-icon>arrow_back</md-icon>Previous</md-button>
				<md-button class="md-raised md-primary" @click="postBursary">Post Bursary</md-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Collapse } from '@/components';

export default {
	components: {
		Collapse,
	},
	props: {
		title: String,
		description: String,
		workPlacementInfo: String,
		requirements: String,
		cv: Object,
		id: Object,
		proofOfIncome: Object,
		matricResults: Object,
		academicTranscript: Object,
		tuitionQuotation: Object,
		other: Object,
		additionalDocuments: Array,
		documentInstructions: String,
		tuition: Boolean,
		accommodation: Boolean,
		meals: Boolean,
		books: Boolean,
		stipend: Boolean,
		additionalCoverDetails: String,
		videoSubmission: String,
		videoSubmissionDetails: String,
		writtenSubmission: String,
		writtenSubmissionDetails: String,
	},
	methods: {
		postBursary() {
			this.$emit('postBursary');
		},
		previousStep() {
			this.$emit('previous', 'review', 'additional');
		},
	},
};
</script>

<style scoped>
.card-description {
	color: #353535 !important;
}

.card-title {
	font-weight: 500 !important;
	margin-bottom: 0px !important;
}

.md-card {
	margin-bottom: 20px;
}

.bursary-icon {
	font-size: 1.5rem !important;
}

.md-icon {
	align-items: flex-start;
}

.margin-left-small {
	margin-left: 0.5rem;
}

.margin-left-medium {
	margin-left: 2.2rem;
}

.margin-top-small {
	margin-top: -1.3rem;
}

.row {
	display: flex;
	align-items: center;
}

.justify-between {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}
</style>
