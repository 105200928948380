var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bursary-container" }, [
    !_vm.bursary || !_vm.employer
      ? _c("div", { staticClass: "background" }, [_vm._m(0)])
      : _vm._e(),
    _vm.bursary && _vm.employer
      ? _c("div", { staticClass: "bursary-content-container" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { staticClass: "bursary-icon" }, [
                _vm._v("business"),
              ]),
              _c("h4", { staticClass: "margin-left-small" }, [
                _vm._v("Company Name"),
              ]),
            ],
            1
          ),
          _c("p", { staticClass: "margin-left-medium margin-top-small" }, [
            _vm._v(_vm._s(_vm.employer.companyName)),
          ]),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { staticClass: "bursary-icon" }, [
                _vm._v("school"),
              ]),
              _c("h4", { staticClass: "margin-left-small" }, [
                _vm._v("Bursary Name"),
              ]),
            ],
            1
          ),
          _c("p", { staticClass: "margin-left-medium margin-top-small" }, [
            _vm._v(_vm._s(_vm.bursary.title)),
          ]),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { staticClass: "bursary-icon" }, [
                _vm._v("description"),
              ]),
              _c("h4", { staticClass: "margin-left-small" }, [
                _vm._v("Bursary Description"),
              ]),
            ],
            1
          ),
          _c("div", {
            staticClass: "margin-left-medium margin-top-small",
            domProps: { innerHTML: _vm._s(_vm.bursary.description) },
          }),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { staticClass: "bursary-icon" }, [
                _vm._v("checklist"),
              ]),
              _c("h4", { staticClass: "margin-left-small" }, [
                _vm._v("Eligibility Requirements"),
              ]),
            ],
            1
          ),
          _c("div", {
            staticClass: "margin-left-medium margin-top-small",
            domProps: { innerHTML: _vm._s(_vm.bursary.bursaryRequirements) },
          }),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { staticClass: "bursary-icon" }, [
                _vm._v("folder"),
              ]),
              _c("h4", { staticClass: "margin-left-small" }, [
                _vm._v("Required Documents"),
              ]),
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "margin-left-small margin-top-small" },
            _vm._l(_vm.hasDocuments(), function (document, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(_vm.formatDocumentName(document)) + " "),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("md-icon", { staticClass: "bursary-icon" }, [
                _vm._v("description"),
              ]),
              _c("h4", { staticClass: "margin-left-small" }, [
                _vm._v("Additional Cover Details"),
              ]),
            ],
            1
          ),
          _c("div", {
            staticClass: "margin-left-medium margin-top-small",
            domProps: { innerHTML: _vm._s(_vm.bursary.additionalCoverDetails) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-position lds-circle" }, [
      _c("div", [
        _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }