<template>
	<div v-if="bursary" class="md-layout" id="top">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<!-- Our wizard container -->
			<simple-wizard :bursary="bursary">
				<template slot="header">
					<h3 class="title">{{ bursary.title }}</h3>

					<div v-if="client" class="flex items-center justify-center">
						<h4 style="margin-right: 2rem">🏢 {{ client.companyName }}</h4>
						<h4>📅 {{ bursary.applicationDeadline }}</h4>
						<h4 style="margin-left: 2rem">⌛ {{ bursaryApplication.applicationStatus }}</h4>
					</div>
					<md-button style="position: absolute; top: 3px; left: 10px; font-size: 30px !important" class="md-simple md-just-icon md-round modal-default-button">
						<md-icon>help</md-icon>
					</md-button>
				</template>

				<!-- STEP 1: Bursary Details -->
				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label">
						<div class="pc-view">Bursary Details</div>
						<div class="mobi-view">
							<i class="fas fa-graduation-cap"></i>
						</div>
					</template>

					<!-- Pass props to the child component here -->
					<bursary-details :bursary="bursary" :employer="client" :bursary-application="bursaryApplication" />
				</wizard-tab>

				<!-- STEP 2: Documents -->
				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label">
						<div class="pc-view">Documents</div>
						<div class="mobi-view">
							<i class="fas fa-folder-open"></i>
						</div>
					</template>

					<submitted-documents />
				</wizard-tab>

				<!-- STEP 3: Offer Details -->
				<wizard-tab :before-change="() => validateStep('step3')">
					<template slot="label">
						<div class="pc-view">Offer Details</div>
						<div class="mobi-view">
							<i class="fas fa-handshake"></i>
						</div>
					</template>

					<!-- Step 3 content goes here... -->
					<div style="padding: 2rem" v-if="bursary && client && bursaryApplication">
						<div v-if="bursaryApplication.applicationStatus === bursaryApplicationStatus.OFFER_SENT">
							<OfferDetails :bursary="bursary" :client="client" :bursaryApplication="bursaryApplication" />
						</div>
						<p class="review-heading" v-else>{{ applicationStatusMessage }}</p>
						<p class="review-heading" v-if="this.bursaryApplication && this.bursaryApplication.applicationStatus === bursaryApplicationStatus.OFFER_ACCEPTED">
							Congratulations! 🥳 <br />
							<br />
							<span style="font-size: 20px"> You have accepted this offer ✅ </span>
						</p>
						<p class="review-heading" v-if="this.bursaryApplication && this.bursaryApplication.applicationStatus === bursaryApplicationStatus.OFFER_REJECTED">
							Sorry 😟 <br />
							<br />
							<span style="font-size: 20px"> You have rejected this offer </span>
						</p>
					</div>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>

<script>
import swal from 'sweetalert2';
import { WizardTab } from '@/components';
import SimpleWizard from './wizard/Wizard.vue'; // or wherever your SimpleWizard file is
import BursaryDetails from './wizard/formSteps/BursaryDetails.vue'; // adjust path as needed
import SubmittedDocuments from './wizard/formSteps/SubmittedDocuments.vue';
import OfferDetails from './wizard/formSteps/OfferDetails.vue';
import firebase from 'firebase/compat/app';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '@/firebase/init';
import { getAuth } from 'firebase/auth';
import { BursaryStatuses } from '../../../../../../constants/bursary-statuses.const';
export default {
	name: 'AppliedBursary',

	components: {
		SimpleWizard,
		WizardTab,
		BursaryDetails,
		SubmittedDocuments,
		OfferDetails,
	},

	data() {
		return {
			bursaryId: this.$route.params.id,
			bursary: null,
			client: null,
			bursaryApplication: null,
			bursaryApplicationStatus: BursaryStatuses,
		};
	},
	computed: {
		applicationStatusMessage() {
			if (this.bursaryApplication && this.bursaryApplication.applicationStatus === BursaryStatuses.IN_PROGRESS) {
				return 'Please complete your application for review';
			}
			if (this.bursaryApplication && this.bursaryApplication.applicationStatus === BursaryStatuses.APPLIED) {
				return 'Your application is currently under review';
			}

			return '';
		},
	},
	async created() {
		try {
			await this.fetchBursaryDocument();
			if (this.bursary && this.bursary.employerId) {
				await this.fetchClientDocument(this.bursary.employerId);
			}
		} catch (error) {
			console.error('Error fetching bursary/client documents:', error);
		}
	},

	methods: {
		async fetchBursaryDocument() {
			const bursaryRef = doc(db, 'bursaries', this.bursaryId);
			const bursarySnap = await getDoc(bursaryRef);
			if (bursarySnap.exists()) {
				this.bursary = bursarySnap.data();

				this.fetchBursaryApplication();
			}
		},
		async fetchBursaryApplication() {
			try {
				const { id: bursaryId } = this.$route.params;
				const user = getAuth().currentUser;
				if (!user) return console.error('No authenticated user found');

				// Fetch user's alias
				const usersSnapshot = await getDocs(query(collection(db, 'users'), where('userId', '==', user.uid)));
				if (usersSnapshot.empty) return console.warn('No user found with UID:', user.uid);

				const alias = usersSnapshot.docs[0]?.data()?.alias;
				if (!alias) return console.error('Alias not found for user');

				// Fetch bursary application using alias and bursaryId
				const applicationSnap = await getDoc(doc(db, 'bursary-applications', `${alias}-${bursaryId}`));
				if (!applicationSnap.exists()) return console.warn('No bursary application found');
				this.bursaryApplication = applicationSnap.data();
			} catch (error) {
				console.error('Error fetching bursary application:', error);
			}
		},
		async fetchClientDocument(employerId) {
			try {
				const clientsCollection = collection(db, 'clients');
				const q = query(clientsCollection, where('userId', '==', employerId));
				const querySnapshot = await getDocs(q);

				if (!querySnapshot.empty) {
					querySnapshot.forEach(docSnap => {
						this.client = docSnap.data();
					});
				}
			} catch (error) {
				console.error('Error fetching client document:', error);
			}
		},

		// Simple example: always returns true. Expand logic to do real validation.
		async validateStep(ref) {
			return true;
		},
	},
};
</script>

<style scoped>
.md-button .md-button-content i {
	font-size: 30px !important;
}

.review-heading {
	font-size: 2rem;
	text-align: center;
}

/* Responsive overrides */
@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
	.md-layout-item.md-xsmall-size-80 {
		min-width: 100%;
		overflow-x: hidden;
	}
	.md-card-wizard .nav-pills > li i {
		font-size: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}
</style>
