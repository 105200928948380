var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "logo-position lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "submissions-container" }, [
        _c("div", { staticClass: "submission-instructions" }, [
          _c("h3", [_vm._v("Submission Instructions")]),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.bursary.documentSubmissionInstructions),
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "submission-documents" },
          [
            _c("h3", [_vm._v("Upload Documents")]),
            _vm._l(_vm.requiredDocuments, function (docType) {
              return _c(
                "div",
                { key: docType },
                [
                  _c(
                    "md-field",
                    {
                      class: {
                        "md-valid": _vm.documents[docType],
                        "md-error": !_vm.documents[docType] && _vm.error,
                      },
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.formatKey(docType)) + ":"),
                      ]),
                      _c("md-file", {
                        attrs: { accept: "application/pdf" },
                        on: {
                          change: function ($event) {
                            return _vm.uploadDocument(docType, $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _vm.bursary.documentationRequirements.other &&
            _vm.bursary.documentationRequirements.other.isRequired &&
            _vm.bursary.additionalDocuments.length
              ? _c(
                  "div",
                  [
                    _c("h3", [_vm._v("Additional Documents")]),
                    _vm._l(
                      _vm.bursary.additionalDocuments,
                      function (doc, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "md-field",
                              {
                                class: {
                                  "md-valid": _vm.additionalDocuments[doc.name],
                                  "md-error":
                                    !_vm.additionalDocuments[doc.name] &&
                                    _vm.error,
                                },
                              },
                              [
                                _c("label", [_vm._v(_vm._s(doc.name) + ":")]),
                                _c("md-file", {
                                  attrs: { accept: "application/pdf" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadAdditionalDocument(
                                        doc.name,
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.submissionSuccessful,
              expression: "submissionSuccessful",
            },
          ],
        },
        [
          _c("template", { slot: "header" }, [
            _c("div", { staticStyle: { "font-size": "2rem" } }, [
              _vm._v("Success! 🎊"),
            ]),
          ]),
          _c("template", { slot: "body" }, [
            _c("p", [_vm._v("Documents Submitted Successfully! ✅")]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-button md-success",
                  on: { click: _vm.closeModal },
                },
                [_vm._v(" Okay")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.showErrorModal
        ? _c("modal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h4", { staticClass: "modal-title black" }, [
                        _vm._v("Whoa there! 🤚"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("h4", [
                        _vm._v("Please submit all required documents! ⛔️"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-success",
                          on: { click: _vm.closeErrorModal },
                        },
                        [_vm._v(" Ok ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4127887838
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }