var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "md-layout" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-content",
              [
                _c(
                  "collapse",
                  {
                    attrs: {
                      collapse: [
                        "Description",
                        "Requirements",
                        "Funding Cover",
                        "Additional Submissions",
                      ],
                      icon: "keyboard_arrow_down",
                      "color-collapse": "success",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "md-collapse-pane-1" },
                      [
                        _c(
                          "md-card",
                          { staticClass: "bg-success" },
                          [
                            _c("md-card-content", [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("school")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Bursary Name")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "margin-left-medium margin-top-small",
                                },
                                [_vm._v(_vm._s(_vm.title))]
                              ),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("description")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Bursary Description")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(_vm.description),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("checklist")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Eligibility Requirements")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(_vm.requirements),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("folder")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Required Documents")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "margin-left-small margin-top-small",
                                },
                                [
                                  _vm.cv.isRequired
                                    ? _c("li", [
                                        _vm._v("CV "),
                                        _vm.cv.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.id.isRequired
                                    ? _c("li", [
                                        _vm._v("ID "),
                                        _vm.id.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.proofOfIncome.isRequired
                                    ? _c("li", [
                                        _vm._v("Proof of Income "),
                                        _vm.proofOfIncome.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.matricResults.isRequired
                                    ? _c("li", [
                                        _vm._v("Matric Results "),
                                        _vm.matricResults.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.academicTranscript.isRequired
                                    ? _c("li", [
                                        _vm._v("Academic Transcript "),
                                        _vm.academicTranscript.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.tuitionQuotation.isRequired
                                    ? _c("li", [
                                        _vm._v("Tuition Quotation "),
                                        _vm.tuitionQuotation.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.other.isRequired
                                    ? _c("li", [
                                        _vm._v("Other "),
                                        _vm.other.shouldCertify
                                          ? _c("span", [_vm._v("(Certified)")])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm.additionalDocuments &&
                              _vm.additionalDocuments.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c(
                                        "md-icon",
                                        { staticClass: "bursary-icon" },
                                        [_vm._v("folder")]
                                      ),
                                      _c(
                                        "h4",
                                        { staticClass: "margin-left-small" },
                                        [_vm._v("Additional Documents")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "margin-left-small margin-top-small",
                                },
                                _vm._l(
                                  _vm.additionalDocuments,
                                  function (document, index) {
                                    return _c("li", { key: index }, [
                                      _vm._v(" " + _vm._s(document.name) + " "),
                                      document.shouldCertify
                                        ? _c("span", [_vm._v("(Certified)")])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("info")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Document Submission Instructions")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(_vm.documentInstructions),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("school")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Funding Cover")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "ul",
                                { staticStyle: { "margin-top": "-1rem" } },
                                [
                                  _c("li", [
                                    _vm._v(
                                      "Tuition: " +
                                        _vm._s(_vm.tuition ? "✅" : "❌")
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Accommodation: " +
                                        _vm._s(_vm.accommodation ? "✅" : "❌")
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Meals: " +
                                        _vm._s(_vm.meals ? "✅" : "❌")
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Books: " +
                                        _vm._s(_vm.books ? "✅" : "❌")
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      "Stipend: " +
                                        _vm._s(_vm.stipend ? "✅" : "❌")
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("description")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Additional Cover Details")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(_vm.additionalCoverDetails),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("place")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Work Placement Information")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(_vm.workPlacementInfo),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.videoSubmission === "Yes",
                                      expression: "videoSubmission === 'Yes'",
                                    },
                                  ],
                                  staticClass: "row",
                                },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("videocam")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Video Submission Details")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.videoSubmission === "Yes",
                                    expression: "videoSubmission === 'Yes'",
                                  },
                                ],
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(_vm.videoSubmissionDetails),
                                },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.writtenSubmission === "Yes",
                                      expression: "writtenSubmission === 'Yes'",
                                    },
                                  ],
                                  staticClass: "row",
                                },
                                [
                                  _c(
                                    "md-icon",
                                    { staticClass: "bursary-icon" },
                                    [_vm._v("assignment")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "margin-left-small" },
                                    [_vm._v("Written Submission Details")]
                                  ),
                                ],
                                1
                              ),
                              _c("div", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.writtenSubmission === "Yes",
                                    expression: "writtenSubmission === 'Yes'",
                                  },
                                ],
                                staticClass:
                                  "margin-left-medium margin-top-small",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.writtenSubmissionDetails
                                  ),
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "justify-between" },
          [
            _c(
              "md-button",
              { staticClass: "md-raised", on: { click: _vm.previousStep } },
              [_c("md-icon", [_vm._v("arrow_back")]), _vm._v("Previous")],
              1
            ),
            _c(
              "md-button",
              {
                staticClass: "md-raised md-primary",
                on: { click: _vm.postBursary },
              },
              [_vm._v("Post Bursary")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }