var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "logo-position lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "md-card",
            {
              staticClass: "md-card-wizard active",
              attrs: { "data-color": "orange" },
            },
            [
              _c(
                "md-card-header",
                [
                  _vm._t("header", function () {
                    return [
                      _c("h3", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _c("h3", { staticClass: "description" }, [
                        _vm._v(_vm._s(_vm.subTitle)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "wizard-navigation" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-pills", attrs: { role: "tablist" } },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "li",
                      {
                        key: tab.title,
                        staticClass: "nav-item wizard-tab-link",
                        style: _vm.linkWidth,
                        attrs: { role: "tab", id: "step-" + tab.tabId },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: [{ active: tab.active }],
                            attrs: { "data-toggle": "tab" },
                            on: {
                              click: function ($event) {
                                return _vm.navigateToTab(index)
                              },
                            },
                          },
                          [_c("tab-item-content", { attrs: { tab: tab } })],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm.activeTab
                  ? _c(
                      "div",
                      {
                        staticClass: "moving-tab",
                        class: { "error-link": _vm.activeTab.hasError },
                        staticStyle: {
                          transition:
                            "transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)",
                          width: "100%",
                        },
                        style: _vm.movingTabStyles,
                      },
                      [
                        _c("tab-item-content", {
                          attrs: { tab: _vm.activeTab, "moving-tab": true },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("md-card-content", [
                _c(
                  "div",
                  { staticClass: "tab-content" },
                  [
                    _vm._t("default", null, {
                      activeIndex: _vm.activeTabIndex,
                      activeTab: _vm.activeTab,
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.submissionSuccessful
        ? _c(
            "modal",
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "font-size": "2rem" } }, [
                  _vm._v("Success! 🎊"),
                ]),
              ]),
              _c("template", { slot: "body" }, [
                _c("p", [
                  _vm._v(
                    "Thank you for your application. The team will be in touch once your application has been evaluated."
                  ),
                ]),
              ]),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-button md-success",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("Okay")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }