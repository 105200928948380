import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export const sendEmailNotification = async (functionName, payload) => {
	try {
		const sendEmail = firebase.functions().httpsCallable(functionName);
		return await sendEmail(payload);
	} catch (error) {
		console.error('Error sending email:', error);
		throw error;
	}
};
