<template>
	<md-card class="offer-details-container">
		<div class="offer-header">
			<div class="offer-info">
				<md-icon> emoji_events </md-icon>
				<h4 class="offer-details-heading">Sponsor</h4>
			</div>
			<p class="offer-text">{{ client.companyName }}</p>
		</div>
		<md-divider></md-divider>

		<div class="w-full flex items-center justify-between" style="flex-wrap: wrap">
			<div class="offer-header">
				<div class="offer-info">
					<md-icon> school </md-icon>
					<h4 class="offer-details-heading">Institution</h4>
				</div>
				<p class="offer-text">{{ bursaryApplication.selectedDegree.university }}</p>
			</div>
			<div class="offer-header">
				<div class="offer-info">
					<md-icon> book </md-icon>
					<h4 class="offer-details-heading">Degree</h4>
				</div>
				<p class="offer-text">{{ bursaryApplication.selectedDegree.degree }}</p>
			</div>
			<div class="offer-header">
				<div class="offer-info">
					<md-icon> calendar_month </md-icon>
					<h4 class="offer-details-heading">Academic Year</h4>
				</div>
				<p class="offer-text">{{ bursaryApplication.selectedDegree.yearOfStudy }}</p>
			</div>
		</div>
		<md-divider></md-divider>

		<div class="offer-coverage">
			<div class="offer-info">
				<md-icon> school </md-icon>
				<h4 class="offer-details-heading">Coverage Details</h4>
			</div>
			<ul class="coverage-list">
				<li v-for="(value, key) in filteredFundingCover" :key="key">
					{{ formatFundingKey(key) }}
				</li>
			</ul>
		</div>

		<div v-if="offer.status === 'Pending'" class="offer-actions">
			<md-button style="margin-right: 2rem" class="md-success" @click="showAcceptanceModal = true" :disabled="offer.status !== 'Pending'"> Accept Offer </md-button>
			<md-button class="md-danger" @click="showRejectionModal = true" :disabled="offer.status !== 'Pending'"> Reject Offer </md-button>
		</div>

		<modal v-if="showRejectionModal">
			<template #header>
				<h4 class="modal-title">You're about to reject an offer 😟</h4>
			</template>
			<template #body>
				<p class="modal-description">Please let us know why you're declining this offer.</p>
				<div class="radio-container">
					<md-radio style="color: black !important" v-model="reason" :value="'Another bursary accepted'">Another bursary accepted</md-radio>
				</div>

				<div class="radio-container">
					<md-radio style="color: black !important" v-model="reason" :value="'No longer interested'">No longer interested</md-radio>
				</div>

				<div class="radio-container">
					<md-radio style="color: black !important" v-model="reason" :value="'Other'">Other</md-radio>
				</div>

				<div v-if="reason === 'Other'" class="other-reason-container">
					<md-field>
						<label for="other-reason">Please specify</label>
						<md-input v-model="otherReason" id="other-reason"></md-input>
					</md-field>
				</div>
			</template>
			<template #footer>
				<md-button class="md-danger" style="margin-right: 1rem" @click="showRejectionModal = false">Cancel</md-button>
				<md-button class="md-success" @click="submitRejection">Submit</md-button>
			</template>
		</modal>

		<modal v-if="showAcceptanceModal">
			<template #header>
				<h3 style="color: black">Accept Bursary Offer</h3>
			</template>
			<template #body>
				<p class="modal-description">
					You are about to accept the bursary offer from <span style="font-weight: bold">{{ client.companyName }}</span> for
					<span style="font-weight: bold">{{ bursaryApplication.selectedDegree.degree }}</span> at
					<span style="font-weight: bold">{{ bursaryApplication.selectedDegree.university }}</span> for
					<span style="font-weight: bold">{{ bursaryApplication.selectedDegree.yearOfStudy }}</span>
				</p>
			</template>
			<template #footer>
				<md-button class="md-danger" style="margin-right: 1rem" @click="showAcceptanceModal = false">Cancel</md-button>
				<md-button class="md-success" @click="acceptOffer">Submit</md-button>
			</template>
		</modal>
		<modal v-if="bursaryOfferAccepted">
			<template #header>
				<h3 style="color: black">Congratulations! 🥳</h3>
			</template>
			<template #body>
				<p class="modal-description">You have accepted this bursary offer ✅</p>
			</template>
			<template #footer>
				<md-button class="md-success" @click="bursaryOfferAccepted = false">Okay</md-button>
			</template>
		</modal>
		<modal v-if="showRejectionAcceptanceModal">
			<template #header>
				<h3 style="color: black">😟</h3>
			</template>
			<template #body>
				<p class="modal-description">You have rejected this bursary offer, sorry to see you go</p>
			</template>
			<template #footer>
				<md-button class="md-success" @click="showRejectionAcceptanceModal = false">Okay</md-button>
			</template>
		</modal>
	</md-card>
</template>

<script>
import Modal from '@/components/Modal';
import db from '@/firebase/init';
import { doc, updateDoc } from 'firebase/firestore';
export default {
	components: { Modal },
	props: {
		bursary: Object,
		client: Object,
		bursaryApplication: Object,
	},
	data() {
		return {
			offer: {
				bursaryTitle: 'Excellence Academic Bursary',
				provider: 'National Education Foundation',
				amount: '100,000',
				status: 'Pending',
			},
			showRejectionModal: false,
			showRejectionAcceptanceModal: false,
			showAcceptanceModal: false,
			bursaryOfferAccepted: false,
			reason: '',
			otherReason: '',
		};
	},
	computed: {
		filteredFundingCover() {
			if (!this.bursary || !this.bursary.fundingCover) return {};
			return Object.fromEntries(Object.entries(this.bursary.fundingCover).filter(([_, value]) => value));
		},
	},
	methods: {
		formatFundingKey(key) {
			const mapping = {
				tuition: 'Tuition Fees',
				accommodation: 'Accommodation',
				stipend: 'Stipend',
				books: 'Prescribed Books',
				meals: 'Meals',
			};
			return mapping[key] || key;
		},
		async acceptOffer() {
			const docRef = doc(db, 'bursary-applications', this.bursaryApplication.studentAlias + '-' + this.$route.params.id);

			try {
				await updateDoc(docRef, {
					applicationStatus: 'Offer Accepted',
				});

				this.offer.status = 'Offer Accepted';
				this.showAcceptanceModal = false;
				this.bursaryOfferAccepted = true;
			} catch (error) {
				console.error('Error updating document:', error);
			}
		},

		async submitRejection() {
			const docRef = doc(db, 'bursary-applications', this.bursaryApplication.studentAlias + '-' + this.$route.params.id);
			const finalReason = this.reason === 'Other' ? this.otherReason : this.reason;
			try {
				await updateDoc(docRef, {
					applicationStatus: 'Offer Rejected',
					rejectionReason: finalReason,
				});

				this.offer.status = 'Offer Rejected';
				this.showRejectionModal = false;
				this.showRejectionAcceptanceModal = true;
			} catch (error) {
				console.error('Error updating document:', error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.offer-details-container {
	max-width: 1200px;
	margin: auto;
	padding: 20px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.offer-header {
	flex-direction: column;
	margin-bottom: 15px;
}

.offer-info {
	display: flex;
	align-items: center;
	width: fit-content;
}

.offer-details-heading {
	margin-left: 0.5rem;
}

.offer-text {
	font-size: 17px;
	margin-top: -1rem;
}

.offer-coverage {
	margin-top: 15px;
}

.coverage-list {
	list-style: none;
	padding: 0;
	margin-top: -1rem;
}

.offer-actions {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.radio-container {
	display: flex;
	flex-direction: flex-start;
	padding: 0 10px 0 10px;
	margin: 0px 0;
}
.modal-description {
	margin-top: -1rem !important;
}
.other-reason-container {
	margin-top: 2rem;
}
</style>
