var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "auto", padding: "0 0 5rem 0" } },
    [
      _vm.loading
        ? _c("div", { staticClass: "background" }, [
            _vm.loading
              ? _c("div", { staticClass: "logo-position lds-circle" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.bursaryApplication && _vm.bursaryApplication.submittedDocuments
        ? _c(
            "div",
            [
              _c(
                "md-card",
                {
                  staticClass: "desktop-table",
                  staticStyle: { margin: "auto", "max-width": "1400px" },
                },
                [
                  _c("md-card-header", [
                    _c("div", { staticClass: "md-title" }, [
                      _vm._v("Submitted Documents"),
                    ]),
                  ]),
                  _c(
                    "md-card-content",
                    [
                      _c(
                        "md-table",
                        [
                          _c(
                            "md-table-row",
                            [
                              _c("md-table-head", [_vm._v("Document")]),
                              _c("md-table-head", [
                                _vm._v("Verification Status"),
                              ]),
                              _c("md-table-head", [
                                _vm._v("Resubmit Required"),
                              ]),
                              _c("md-table-head"),
                              _c("md-table-head"),
                              _c("md-table-head"),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.bursaryApplication.submittedDocuments,
                            function (doc, docType) {
                              return _c(
                                "md-table-row",
                                { key: docType },
                                [
                                  _c("md-table-cell", [
                                    _vm._v(_vm._s(_vm.formatKey(docType))),
                                  ]),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          doc.isVerified && !doc.shouldResubmit
                                            ? "Verified"
                                            : "Not Verified"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("md-table-cell", [
                                    _vm._v(
                                      _vm._s(doc.shouldResubmit ? "Yes" : "No")
                                    ),
                                  ]),
                                  _c(
                                    "md-table-cell",
                                    [
                                      _c(
                                        "md-button",
                                        {
                                          staticClass: "resubmission-btn",
                                          staticStyle: {
                                            width: "fit-content !important",
                                            "background-color":
                                              "#558fe6 !important",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewDoc(doc.url)
                                            },
                                          },
                                        },
                                        [_vm._v(" View ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "md-table-cell",
                                    [
                                      _c("input", {
                                        ref: "fileInput_" + docType,
                                        refInFor: true,
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept: "application/pdf",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleFileChange(
                                              $event,
                                              docType
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "md-button",
                                        {
                                          staticClass: "md-success",
                                          staticStyle: {
                                            width: "fit-content !important",
                                          },
                                          attrs: {
                                            disabled: !doc.shouldResubmit,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.triggerFileUpload(
                                                docType
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Resubmit ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("md-table-cell", [
                                    _c(
                                      "span",
                                      {
                                        style: {
                                          color:
                                            doc.isVerified &&
                                            !doc.shouldResubmit
                                              ? "green"
                                              : "red",
                                          fontSize: "1rem",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              doc.isVerified &&
                                                !doc.shouldResubmit
                                                ? "✅"
                                                : "❌"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _vm.hasComments
                        ? _c(
                            "md-card",
                            { staticClass: "feedback-card" },
                            [
                              _c("md-card-header", [
                                _c(
                                  "div",
                                  { staticClass: "md-title" },
                                  [
                                    _c(
                                      "md-icon",
                                      { staticClass: "feedback-icon" },
                                      [_vm._v("comment")]
                                    ),
                                    _vm._v(" Submission Feedback "),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "md-card-content",
                                _vm._l(
                                  _vm.bursaryApplication.submittedDocuments,
                                  function (doc, docType) {
                                    return _c(
                                      "div",
                                      {
                                        key: docType,
                                        staticClass: "feedback-doc-type",
                                      },
                                      [
                                        doc.comments && doc.comments.comment
                                          ? _c("div", [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass: "doc-type-title",
                                                },
                                                [
                                                  _c(
                                                    "md-icon",
                                                    {
                                                      staticClass:
                                                        "doc-type-icon",
                                                    },
                                                    [_vm._v("description")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatKey(docType)
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "comment-item" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "comment-timestamp",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.formatTimestamp(
                                                              doc.comments.date
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "comment-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          doc.comments.comment
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "mobile-cards",
                  staticStyle: { "max-width": "1400px", margin: "auto" },
                },
                [
                  _c("div", { staticClass: "cards-heading" }, [
                    _vm._v("Submitted Documents"),
                  ]),
                  _vm._l(
                    _vm.bursaryApplication.submittedDocuments,
                    function (doc, docType) {
                      return _c(
                        "md-card",
                        { key: docType, staticClass: "doc-card" },
                        [
                          _c("md-card-header", [
                            _c("div", { staticClass: "doc-title" }, [
                              _vm._v(
                                " " + _vm._s(_vm.formatKey(docType)) + " "
                              ),
                            ]),
                          ]),
                          _c("md-card-content", [
                            _c("div", { staticClass: "doc-field" }, [
                              _c("strong", [_vm._v("Verification Status:")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    doc.isVerified && !doc.shouldResubmit
                                      ? "Verified"
                                      : "Not Verified"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "doc-field" }, [
                              _c("strong", [_vm._v("Resubmit Required:")]),
                              _vm._v(
                                " " +
                                  _vm._s(doc.shouldResubmit ? "Yes" : "No") +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "actions-container" },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "resubmission-btn",
                                    staticStyle: {
                                      width: "fit-content !important",
                                      "background-color": "#558fe6 !important",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDoc(doc.url)
                                      },
                                    },
                                  },
                                  [_vm._v(" View ")]
                                ),
                                _c("input", {
                                  ref: "fileInput_" + docType,
                                  refInFor: true,
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    type: "file",
                                    accept: "application/pdf",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleFileChange(
                                        $event,
                                        docType
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success",
                                    staticStyle: {
                                      width: "fit-content !important",
                                      "margin-left": "1rem",
                                    },
                                    attrs: { disabled: !doc.shouldResubmit },
                                    on: {
                                      click: function ($event) {
                                        return _vm.triggerFileUpload(docType)
                                      },
                                    },
                                  },
                                  [_vm._v(" Resubmit ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  _vm.hasComments
                    ? _c(
                        "md-card",
                        { staticClass: "feedback-card" },
                        [
                          _c("md-card-header", [
                            _c(
                              "div",
                              { staticClass: "md-title" },
                              [
                                _c(
                                  "md-icon",
                                  { staticClass: "feedback-icon" },
                                  [_vm._v("comment")]
                                ),
                                _vm._v(" Submission Feedback "),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "md-card-content",
                            _vm._l(
                              _vm.bursaryApplication.submittedDocuments,
                              function (doc, docType) {
                                return _c(
                                  "div",
                                  {
                                    key: docType,
                                    staticClass: "feedback-doc-type",
                                  },
                                  [
                                    doc.comments && doc.comments.comment
                                      ? _c("div", [
                                          _c(
                                            "h3",
                                            { staticClass: "doc-type-title" },
                                            [
                                              _c(
                                                "md-icon",
                                                {
                                                  staticClass: "doc-type-icon",
                                                },
                                                [_vm._v("description")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatKey(docType)
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "comment-item" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "comment-timestamp",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatTimestamp(
                                                          doc.comments.date
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "comment-text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(doc.comments.comment)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showModal
        ? _c("Modal", {
            on: { close: _vm.closeModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "md-title" }, [
                        _vm._v("Resubmit Document"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _vm.uploadSuccess
                        ? _c("p", { staticClass: "upload-success-text" }, [
                            _vm._v("🎉🎉 Document uploaded successfully! 🎉🎉"),
                          ])
                        : _vm._e(),
                      !_vm.uploadSuccess
                        ? _c("p", { staticClass: "upload-error-text" }, [
                            _vm._v(
                              "Error uploading document. Please try again."
                            ),
                          ])
                        : _vm._e(),
                      _vm.uploadSuccess
                        ? _c(
                            "md-button",
                            {
                              staticClass: "md-button md-primary",
                              staticStyle: { "margin-left": "10px" },
                              on: { click: _vm.viewUploadedDocument },
                            },
                            [_vm._v(" View Document ")]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "md-button",
                        {
                          staticClass: "md-button md-success",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("Okay")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2547845240
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/img/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }